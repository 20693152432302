<section>
    <div class="box mt-5">
        <div class="title_content text-center">
        <h2 class="text-center"><b>Social Wall</b></h2>
        <img src="../../../assets/img/MLZS/prg_text_bg.png" class="text_bg" alt="">
    </div>
        <p class="text-center m-0">Get a sneak peek into the vibrant life at Mount Litera Zee School, featuring snapshots of our latest activities, events,</p>
        <p class="text-center m-0">achievements, and moments shared by our students, staff, and community.</p>
        <div class="cascade-slider_container" id="cascade-slider">
            <div class="cascade-slider_slides">
              <div class="cascade-slider_item">
               <div class='card align'>
                <!-- <img src="../../../assets/img/MLZS/social_wall.png" width="100%" alt=""> -->
                <iframe width="100%" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FKidzeeJonnyKids%2Fposts%2Fpfbid0yDs1DnPneQyxhDccg8vS1uya782K1fQ8U2dWzFqKJ7THaddjzNtnBLZmX7Y2oPV7l&amp;show_text=true&amp;width=500" width="500" height="684" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
              </div>
              <div class="cascade-slider_item">
               <div class='card align'>
                <!-- <img src="../../../assets/img/MLZS/social_wall.png" width="100%" alt=""> -->
                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FKidzeeJonnyKids%2Fposts%2Fpfbid02TAYL3NfFeer4Nccf6MHcVZyHr3PaUQuNsDHkVsojqzF5CaMs3MML6XoZnencmX3ql&amp;show_text=true&amp;width=500" width="500" height="665" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
              </div>
              <div class="cascade-slider_item">
               <div class='card align'>
                <!-- <img src="../../../assets/img/MLZS/social_wall.png" width="100%" alt=""> -->
                <iframe width="100%" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FKidzeeJonnyKids%2Fposts%2Fpfbid0yDs1DnPneQyxhDccg8vS1uya782K1fQ8U2dWzFqKJ7THaddjzNtnBLZmX7Y2oPV7l&amp;show_text=true&amp;width=500" width="500" height="684" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
              </div>
             
            </div>
            <!-- <span class="cascade-slider_arrow cascade-slider_arrow-left" data-action="prev"><img src="https://agenciafagulha.com.br/wp-content/uploads/2019/12/chvron-left.svg" type="image/svg+xml"></span>
            <span class="cascade-slider_arrow cascade-slider_arrow-right" data-action="next"><img src="https://agenciafagulha.com.br/wp-content/uploads/2019/12/chvron-right.svg" type="image/svg+xml"></span> -->
          </div>
          <ol class="cascade-slider_nav">
            <li class="cascade-slider_dot cur"></li>
            <li class="cascade-slider_dot"></li>
            <li class="cascade-slider_dot"></li>
          </ol>
    </div>
</section>