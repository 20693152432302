<section>
    <div class="mainDiv">
        <!-- <div class="banner_content">
            <div class="whiteTitle">{{headerTitle}}</div>
            <div class="subMenu_content">
                <app-sub-menu></app-sub-menu>
            </div>
        </div> -->
        <div class="banner_content">
            <img src="../../../assets/img/new-kidzee/dr_blue_bg.png" class="yello_bg_img" height="100%" alt="">
            <img src="../../../assets/img/new-kidzee/program_header_img.png" class="yello_bg_img_mobile" height="100%" alt="">
            <img src="../../../assets/img/new-kidzee/abcd.png" class="three_child" alt="">
            <img src="../../../assets/img/new-kidzee/left_img.png" class="left_img" alt="">
                <h1 class="whiteTitle">{{headerTitle}}</h1>
                <div class="subMenu_content">
                    <app-sub-menu></app-sub-menu>
                </div>
                <img src="../.././../assets/img/new-kidzee/btrfly_img.png" class="btr_fly_img" alt="">
            </div>

        <div class="sub_content">
            <div class="row">
                <div class="col-md-12">
                    <div class="text_content">
                        <div class="listTitle mt-3">Teacher Training Programme</div>
                     
                        <div class="paraText mt-4">
                            <p>Zee Learn Preschool Teacher Training Programme is a Zee Learn Ltd initiative. Kidzee (Preschool vertical of Zee Learn Ltd) has set unrivalled standards in the CDE (Child Development & Education) space as a leader in ECCE (Early Childhood Care & Education). Kidzee is one of the leading preschool chains in India.</p>
                            <p>With the onset of NEP, whihc regulates preschool education in India; it is mandatory that all preschool teachers are trained in Early Child Education to be eligible to teach at the Foundational stage.</p>
                           <p>Our pedagogy, Péntemind nurtures the ‘Learning Minds’ in every child. It is a well-researched curriculum by our team of experts in preschool education. Early Childhood Education is now identified as the most critical need in the current global scenario. With increasing demands and recognition, there is a greater need for quality educators or preschool experts. This teacher training course will lead to the creation of world-class preschool educators.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contentList">
            <div class="row">
                <div class="col-md-12">
                    <div class="listTitle">Key Areas in Playgroup Curriculum</div>
                    <div class="text_list">
                        <ul class="li_ul">
                            <li><span class="liText">Effective classroom techniques for teachers - Each module of the course is based on what is the most effective method/practice for the child's development in a preschool setting or at home.</span></li>
                            <li><span class="liText">Lectures on instructional theory associated with hands-on learning experience - It is intended to improve and simplify understanding of Early Childhood theories and via their execution and implementation in a preschool classroom.</span></li>
                            <li><span class="liText">Theory lectures with hands-on experience - It is intended to improve and simplify understanding of Early Childhood theories and via their execution and implementation in a preschool classroom. The Science of Preschool Education.</span></li>
                            <li><span class="liText">Industry experts as Guest Lecturers - Experts from various domains will be invited to interact with the participants in order to add value to their knowledge that will be gained from the ongoing trends in the industry.</span></li>
                            <li><span class="liText">Job Placement - Assistance to participants to secure a job at Kidzee or other preschools based on availability and achievements.</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row mt-12">
                <div class="col-md-12">
                    <div class="listTitle">Course Details</div>
                    <div class="text_list">
                        <ul class="li_ul">
                            <li><span class="liText">4 months  to 6 months.</span></li>
                            <li><span class="liText">3 months theory + 1 month on field internship</span></li>
                            <li><span class="liText">Batch Capacity – 15 participants, 1 trainer (for ideal and effective learning)</span></li>
                            <li><span class="liText">Eligibility – Females Only. Minimum H.S.C Passed and above 18 years of Age.</span></li>
                            </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text_list_banner">
                    <div class="listTitle">Basic</div>
                    <div class="text_list">
                        <ul class="li_ul">
                            <li><span class="liText">Introduction of ECE</span></li>
                            <li><span class="liText">Attributes of a ECED teacher</span></li>
                            <li><span class="liText">Development and its types</span></li>
                            <li><span class="liText">Pioneers of child development</span></li>
                            <li><span class="liText">Health, hygiene & safety</span></li>
                            </ul>
                    </div>
                </div>
                </div>
                <div class="col-md-4">
                    <div class="text_list_banner">
                    <div class="listTitle">Advanced</div>
                    <div class="text_list">
                        <ul class="li_ul">
                            <li><span class="liText">Communication skills</span></li>
                            <li><span class="liText">Settling period & Behavior management</span></li>
                            <li><span class="liText">Teaching aids</span></li>
                            <li><span class="liText">Story/Rhyme telling</span></li>
                            <li><span class="liText">Designing worksheets & lesson plans</span></li>
                            </ul>
                    </div>
                </div>
                </div>

                <div class="col-md-4">
                    <div class="text_list_banner">
                    <div class="listTitle">Premium</div>
                    <div class="text_list">
                        <ul class="li_ul">
                            <li><span class="liText">Assessment & Records</span></li>
                            <li><span class="liText">Phonics</span></li>
                            <li><span class="liText">Celebrations/Other activities</span></li>
                            <li><span class="liText">Parent connect</span></li>
                            </ul>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="faqs_content">
            <app-faqs></app-faqs>
        </div>
        <div class="blogs_content">
            <app-blogs></app-blogs>
        </div>
       
    </div>
    <!-- <div class="bottomMonkey">
        <img srcset="../../../assets/img/monkeyWhithLight.png" loading="lazy" class="monkeyImgList" alt="">
    </div> -->
</section>