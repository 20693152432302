<section>
    <div class="mainDiv">
        <div class="subContent">
            <div class="centers_title text-center">Our Preschool Centers in {{cityname}}</div>
            <div class="dropDownList mt-4">
                <form action="">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <select name="" id="" class="form-control  dropdown-toggle" #city
                                    (change)="oncitychange(city.value)">
                                    <option value="">All</option>
                                    <option *ngFor="let dt of allcityList" value="{{dt.Franchisee_Code}}">
                                        {{dt.Franchisee_Name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4 res_input">
                            <div class="form-group">
                                <input type="text" class="form-control filter" id="inputdata"
                                    placeholder="Search by Pincode" (keyup)="fn_ClientFilter($event)" />
                            </div>
                        </div>
                        <div class="col-md-4 res_input">
                            <div class="form-group d-flex justify-content-end">
                                <button class="btn map_btn search_btn">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="citylist.length==0">
                No Record Found!
            </div>


            <!-- <div class="numb_list_mob">
                <div id="carouselExampleIndicators10" class="carousel slide">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators10"
                            aria-current="true" aria-label="Slide 1"
                            *ngFor="let dt of citylist;let i=index"
                            [attr.data-bs-slide-to]="i" [ngClass]="{'active' : i == 0}"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active" *ngFor="let dt of citylist;let i=index" [ngClass]="(i === 0) ? 'active' : ''">
                            <div class="card_content mt-5">
                                <div class="card_title">{{dt.Franchisee_Name}}</div>
                                <div class="location_content">
                                    <div class="locationIcon mt-2">
                                        <img src="../../../assets/img/location.png" alt="" class="location_img">
                                        <p>{{dt.Address1}} {{dt.Address2}} {{dt.Place}}</p>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="three_icon mt-3 phone_wp d-flex">
                                                <div class="d-flex wp_icon">
                                                    <a target="_blank"
                                                        href="https://wa.me/{{dt.Mobile_No}}?text=I'm%20interested%20in%20your%20Kidzee centre%20for%20admission"
                                                        *ngIf="dt.Mobile_No!=''">
                                                        <img src="../../../assets/img/WhatsApp Icon.png" class="iconList" loading="lazy"
                                                            alt=""><span class="mx-2">{{dt.Mobile_No}}</span></a>
                                                </div>
                                                <div class="d-flex">
                                                    <a href="tel:{{dt.Mobile_No}}" *ngIf="dt.Mobile_No!=''"><img
                                                            src="../../../assets/img/Call Icon.png" class="iconList mx-3" loading="lazy"
                                                            alt="">{{dt.Mobile_No}}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex">
                                            <a class="w-100" href="https://www.google.com/maps/search/?api=1&query={{dt.Latitude}},{{dt.Longitude}}"
                                                target="_blank"><button class="btn map_btn mt-3">Map Direction</button></a>
                                            <button *ngIf="dt.viewurl360!=''" class="btn map_btn mt-3" data-bs-toggle="modal"
                                                data-bs-target="#staticBackdrop" (click)="openVirtualUrl(dt.virtualurl)">Virtual
                                                Tour</button>
                                            <button class="btn map_btn mt-3" (click)="setaddress(dt)">Enquire Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div> -->


            <!-- desk_content -->
           <div class="">
            <div class="row">
                <div class="col-md-4" *ngFor="let dt of citylist">
            <div class="card_content mt-5">
                <div class="card_title">{{dt.Franchisee_Name}}</div>
                <div class="location_content">
                    <div class="locationIcon mt-2">
                        <img src="../../../assets/img/location.png" alt="" class="location_img">
                        <p>{{dt.Address1}} {{dt.Address2}} {{dt.Place}}</p>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="three_icon mt-3 phone_wp d-flex">
                                <div class="d-flex wp_icon">
                                    <a target="_blank"
                                        href="https://wa.me/{{dt.Mobile_No}}?text=I'm%20interested%20in%20your%20Kidzee centre%20for%20admission"
                                        *ngIf="dt.Mobile_No!=''">
                                        <img src="../../../assets/img/WhatsApp Icon.png" class="iconList" loading="lazy"
                                            alt=""><span class="mx-2">{{dt.Mobile_No}}</span></a>
                                </div>
                                <div class="d-flex">
                                    <a href="tel:{{dt.Mobile_No}}" *ngIf="dt.Mobile_No!=''"><img
                                            src="../../../assets/img/Call Icon.png" class="iconList mx-3" loading="lazy"
                                            alt="">{{dt.Mobile_No}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex">
                            <a class="w-100" href="https://www.google.com/maps/search/?api=1&query={{dt.Latitude}},{{dt.Longitude}}"
                                target="_blank"><button class="btn map_btn mt-3">Map Direction</button></a>
                            <button *ngIf="dt.viewurl360!=''" class="btn map_btn mt-3" data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop" (click)="openVirtualUrl(dt.virtualurl)">Virtual
                                Tour</button>
                            <button class="btn map_btn mt-3" (click)="setaddress(dt)">Enquire Now</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>
        </div>
    </div>
</section>