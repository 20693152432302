import { Component } from '@angular/core';
declare var $: any;  // Declare jQuery

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent {
 headerTitle="In News"


 ngAfterViewInit(): void {
  setTimeout(()=>{
    var owl = $(".news_owl");
  owl.owlCarousel({
    items: 4,
    margin: 10,
    loop: true,
    nav: false
  });
  },1000)

}


}
