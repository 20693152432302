
<div class="">
  <div class="top_header d-flex justify-content-center justify-align-center text-center" id="fix_header">
    <button class="btn btn-sm text-white admission_btn">Admissions now open at Mount Litera Zee School</button>
    <button class="btn btn-sm text-white right_botder_btn mx-3">Partner with us</button>
    <button class="btn btn-sm text-white convert_btn">Convert to an MLZS</button>
  </div>

<!-- <nav class="navbar navbar-expand-lg bg-body-tertiary navbar1" id="mobNav">
  <img src="../../../../assets/img/MLZS/mtl.png" width="15%" href="" class="kidzee_logo_img" alt="">
     
    <div class="collapse navbar-collapse nav_body" id="navbarNavDropdown">
       <ul class="navbar-nav ul_content">
        <li class="nav-item">
          <a class="nav-link active menu1" aria-current="page" href="">Home</a>
        </li>
        <li class="nav-sub-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          (click)="gotPage()">
            About Us
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" routerLink="/legacy" (click)="gotPage()">Legacy</a>
            <a class="dropdown-item" routerLink="/kidzee-advantage" (click)="gotPage()">Kidzee Advantage</a>
            <a class="dropdown-item" routerLink="/vision" (click)="gotPage()">Vision & Mission</a>
            <a class="dropdown-item" routerLink="/awards-and-recognition" (click)="gotPage()">Awards & Recognized</a>
          </div>
        </li>
        <li class="nav-sub-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Programmes
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" id="target">
            <a class="dropdown-item" routerLink="/playgroup" (click)="gotPage()">Playgroup</a>
            <a class="dropdown-item" routerLink="/nursery" (click)="gotPage()">Nursery</a>
            <a class="dropdown-item" routerLink="/kindergarten" (click)="gotPage()">Kindergarten</a>
            <a class="dropdown-item" routerLink="/teacher-training-programme" (click)="gotPage()">Teacher Training Programme</a>
            <a class="dropdown-item" routerLink="/Daycare" (click)="gotPage()">Daycare</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link active menu1" aria-current="page" routerLink="/pentemind" (click)="gotPage()">Péntemind</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active menu5" aria-current="page" routerLink="/admissions" (click)="gotPage()">Admissions</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active menu" aria-current="page" routerLink="/partner-with-kidzee" (click)="gotPage()">Franchise Opportunity</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" routerLink="/locateUs" (click)="gotPage()">Locate Us</a>
        </li>

      </ul>
    </div>
</nav> -->
</div>
<div class="whiteHeader" id="whiteHeader">
    <nav class="nav navbar navbar-expand-lg">
        <div class="container-fluid">
          <div class="menu_icon" data-bs-toggle="collapse" data-bs-target="#navbarNav"></div>
          <div class="collapse navbar-collapse " id="navbarNav">
            <img src="../../../../assets/img/MLZS/mtl.png" width="13%" class="kidzee_logo_img" href="" alt="">
            <ul class="navbar-nav ul_content">
              <li class="nav-item">
                <a class="nav-link active menu1" aria-current="page" href="/">ABOUT US</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" aria-current="page" routerLink="/programmes">PROGRAMMES</a>
              </li>
          
              <li class="nav-item">
                <a class="nav-link active menu1" aria-current="page" routerLink="/">ACADEMICS & BEYOND</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active menu5" aria-current="page" routerLink="/">START A SCHOOL</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active menu" aria-current="page" routerLink="/">PARENTS CORNER</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" routerLink="/">ADMISSIONS</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" routerLink="/">CONTACT US</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" routerLink="/">DISCOVER MORE</a>
              </li>
            </ul>
            <img src="../../../../assets/img/MLZS/imtb.png" class="brand_icon" width="13%" alt="">
          </div>
        </div>
      </nav>

</div>
<router-outlet></router-outlet>
<app-footer></app-footer> 