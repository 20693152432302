import { Component } from '@angular/core';

@Component({
  selector: 'app-home-about-us',
  templateUrl: './home-about-us.component.html',
  styleUrls: ['./home-about-us.component.css']
})
export class HomeAboutUsComponent {

}
