<section>
    <div class="legancy_content">
        <img src="../../../assets/img/new-kidzee/yellow_bg.png" class="yello_bg_img" height="100%" alt="">
        <img src="../../../assets/img/new-kidzee/three_child.png" class="three_child" alt="">
        <img src="../../../assets/img/new-kidzee/left_img.png" class="left_img" alt="">
       
        <h1 class="whiteTitle">{{headerTitle}}</h1>
        <div class="subMenu_content">
           <app-sub-menu></app-sub-menu>
        </div>
    </div>
    <div class="buttonList_content">
        <button class="btn grey_btn" [ngClass]="Legacy ? 'activeTab' : null" routerLink="/legacy" (click)="scrollToPosition();">Legacy</button>
        <button class="btn grey_btn" [ngClass]="kidzeeAdvantage ? 'activeTab' : null" routerLink="/kidzee-advantage" (click)="scrollToPosition();">Kidzee Advantage</button>
        <button class="btn grey_btn" [ngClass]="eduction? 'activeTab' : null" routerLink="/vision" (click)="scrollToPosition();">Vision & Mission</button>
        <button class="btn grey_btn" [ngClass]="awards ? 'activeTab' : null" routerLink="/awards-and-recognition" (click)="scrollToPosition();">Awards & Recognition</button>
     </div>
    
    <div class="mainDiv mt-5" *ngIf="Legacy">
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-md-12">
                <div class="rightDiv">
                    <img src="../../../assets/img/new-kidzee/chocolate_img.png" class="chocolate_img" alt="">
                    <h1 class="title mt-4">Legacy</h1>
                    <img src="../../../assets/img/new-kidzee/bottom_img.png" width="130px" height="100%" alt="">
                    <h3 class="mt-3">Kidzee Preschool</h3>
                    <img src="../../../assets/img/new-kidzee/bag_img.png" class="bag_img" alt="">
                    <div class="paragraph_content mt-2">
                        <p>We believe that there is ‘unique potential’ in every child; and Kidzee nurtures it. Touted to
                            be one of the best preschools in India, we nurture and shape minds with the help of our
                            best-in-class age appropriate progressive curriculum. We are changing the face of early
                            childhood education with consistent upgradation and innovation to meet the current needs
                            with a proactive future, focussed on grooming them to be ‘ever-ready for life’.

                            Our commitment towards quality education also values aspects like self-reliance, peer
                            interaction, and individual growth. Our solid foundation and polished business model makes
                            it profitable for all the stakeholders involved.</p>
                    </div>
                </div>
                <img src="../../../assets/img/new-kidzee/mouse_img.png" width="5%" class="mouse_img" alt="">
            </div>
        </div>
    </div>

    <div class="mainDiv mt-5" *ngIf="kidzeeAdvantage">
        <img src="../../../assets/img/new-kidzee/chocolate_img.png" class="chocolate_img" alt="">
        <div class="row">
            <div class="title_kidzee mt-4 d-lg-block d-sm-none d-md-none d-none">"Ranked amongst the top 100 franchise opportunities by Franchise India for 2022, we are the experts in Early Childhood Care and Education.</div>
            <div class="col-md-12">
                <div class="title_kidzee mt-4 d-lg-none">"Ranked amongst the top 100 franchise opportunities by Franchise India for 2022, we are the experts in Early Childhood Care and Education.</div>
                <div class="subTitle_kidzee">We encourage, educate, and equip today's children to be tomorrow's leaders.</div>
               <div class="listTitle">The Golden Five-Point Kidzee Edges:</div>
                <div class="leftDiv1">
                    <img src="../../../assets/img/new-kidzee/bag_img.png" class="bag_img" alt="">
                    <div class="paragraph_content mt-2">
                       <ul class="kidzee_points">
                        <li>Focus on What is Right for the Child.</li>
                          <li>Safety and security is a priority.</li>
                          <li>Integrated Pentemind Curriculum</li>
                          <li>I Care and ECE trained facilitators</li>
                        <li>An innovative pegagogy aligned to NEP 2020 designed to help nuture collaborative team player and breakthrough leaders</li>
                        <li>Kidzee App</li>
                       
                    </ul>
                    </div>
                </div>
                <img src="../../../assets/img/new-kidzee/fruit_img.png" class="fruit_img" alt="">
                <!-- <div class="listTitle">The Golden Five-Point Kidzee Edges:</div>
                <div class="leftDiv1">
                    
                    <div class="paragraph_content mt-2">
                       <ul class="kidzee_points">
                        <li>Kidzee is a <b>leader</b> and a trendsetter in <b>Early Childhood Care and Education (ECCE).</b> Our business partners’ passion and drive are vital for our brand’s success.</li>
                          <li>Our Business Partners don't just take the name, they also take our <b>pedagogy- PéNTEMiND,</b> guidelines to teach, prescribes books/plays to be followed and so on to the ground.</li>
                          <li>Starting a preschool requires a strategic researching, planning, designing, and organising. <b>Kidzee Professional  research,</b> makes this journey easier and rewarding for all our Business Partners.</li>
                          <li><b>Secret to Success is Service and  Support.</b>We provide pre-opening and operational support apart from the regular support that is given.n additional to all systems and process.</li>
                       </ul>
                    </div>
                    <img src="../../../assets/img/new-kidzee/mouse_img.png" class="mouse_img" alt="">
                </div> -->
                
            </div>
        </div>
    </div>

    <div class="mainDiv mt-5" *ngIf="eduction">
        <div class="row">
            <div class="col-md-12">
                <img src="../../../assets/img/new-kidzee/chocolate_img.png" class="chocolate_img" alt="">
                <div class="title_kidzee mt-4">Vision of our preschool education</div>
                <img src="../../../assets/img/new-kidzee/bag_img.png" class="bag_img" alt="">
                <div class="leftDiv1">
                    <img src="../../../assets/img/new-kidzee/fruit_img.png" class="fruit_img" alt="">
                    
                    <div class="paragraph_content mt-2">
                        <p>A child's human rights are non-negotiable for us.</p>
                     <p> We prioritise a child's human rights as part of our ‘What's Right for the Child’ (WRFC) ethos. Our fundamental belief and ideology is shaped by the right to trust, the right to be respected, and the right to live in an abuse-free world.</p>
                    </div>
                </div>
                
            </div>
        </div>
        <img src="../../../assets/img/new-kidzee/mouse_img.png" class="mouse_img" alt="">
    </div>

    <div class="mainDiv mt-5" *ngIf="awards">
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-md-12">
                <div class="rightDiv">
                    <img src="../../../assets/img/new-kidzee/chocolate_img.png" class="chocolate_img" alt="">
                    <div class="title_kidzee mt-4">India's most recognized Preschool Franchise</div>
                    <img src="../../../assets/img/new-kidzee/bag_img.png" class="bag_img" alt="">
                    <div class="yearsListTab">
                       <div class="active_years" (click)="clickYear(1)" id="year1">2022</div>
                       <div class="years" (click)="clickYear(2)" id="year2">2021</div>
                       <div class="years" (click)="clickYear(3)" id="year3">2020</div>
                       <div class="years" (click)="clickYear(4)" id="year4">2019</div>
                       <div class="years">and Before</div>
                    </div>
                    <img src="../../../assets/img/new-kidzee/fruit_img.png" class="fruit_img" alt="">
                    <div class="yearInfo_content">
                        <ul class="yearContentList" *ngIf="year22">
                            <li>India's Most Trusted Preschool: TRA's Brand Trust Report, 2022</li>
                            <li>Ranked amongst the Top 100 Franchise Opportunities, 2022: Franchise India</li>
                        </ul>
                        <ul class="yearContentList" *ngIf="year21">
                            <li>India's Most Trusted Preschool: TRA's Brand Trust Report, 2021</li>
                            <li>Ranked amongst the Top 100 Franchise Opportunities, 2021: Franchise India</li>
                        </ul>
                        <ul class="yearContentList" *ngIf="year20">
                            <li>India's Most Trusted Preschool: TRA's Brand Trust Report, 2020</li>
                            <li>Ranked amongst the Top 100 Franchise Opportunities, 2020: Franchise India</li>
                        </ul>
                        <ul class="yearContentList" *ngIf="year19">
                            <li>India's Most Trusted Preschool: TRA's Brand Trust Report, 2019</li>
                            <li>Ranked amongst the Top 100 Franchise Opportunities, 2019: Franchise India</li>
                        </ul>
                    </div>
                </div>
                <img src="../../../assets/img/new-kidzee/mouse_img.png" class="mouse_img" alt="">
            </div>
        </div>
    </div>
</section>