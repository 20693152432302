<section>
    <div class="about_content">
    <div class="box">
        <div class="row">
            <div class="col-md-12">
                <div class="title_content text-center">
                    <h2 class="text-white"><b>News & Events</b></h2>
                    <img src="../../../assets/img/MLZS/text-bg.png" class="text_bg" alt="">
                    <div class="text-white">
                        <p>Want to know about the exciting events at Mount Litera Zee School?</p>
                        <p>From academic milestones to vibrant extracurriculars, discover all about the latest updates and celebrations within our school community.</p>
                       </div>
                </div>

                <div class="home-demo mt-3">
                    <div class="owl-carousel owl-theme news_owl" id="">
                      <div class="item">
                        <div class="item_img_content">
                          <img src="../../../assets/img/MLZS/news1.png" alt="">
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                        
                          <h6>Zee Learn as the Most Trusted Education Brand</h6>
                          <p>8 MARCH 24</p>
                        </div>
                      </div>
                      <div class="item">
                        <div class="item_img_content">
                          <img src="../../../assets/img/MLZS/news2.png" alt="">
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                        
                          <h6>Mount Litera Zee School learning journey </h6>
                          <p>4 MAY 24</p>
                        </div>
                      </div>
                      <div class="item">
                        <div class="item_img_content">
                          <img src="../../../assets/img/MLZS/news3.png" alt="">
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                        
                          <h6>Mount Litera Zee School learning journey</h6>
                          <p>4 MAY 24</p>
                        </div>
                      </div>
                      <div class="item">
                        <div class="item_img_content">
                          <img src="../../../assets/img/MLZS/news3.png" alt="">
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                        
                          <h6>Zee Learn as the Most Trusted Education Brand</h6>
                          <p>8 MARCH 24</p>
                        </div>
                      </div>
                      <div class="item">
                        <div class="item_img_content">
                          <img src="../../../assets/img/MLZS/news3.png" alt="">
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                        
                          <h6>Zee Learn as the Most Trusted Education Brand</h6>
                          <p>8 MARCH 24</p>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
       
    </div>
</div>
</section>