<section>
  <div class="about_content">
    <div class="box mt-5">
      <!-- <div class="row">
            <div class="col-md-6">
                <div class="title_content text-center">
                    <h2 class="text-white"><b>News & Events</b></h2>
                    <img src="../../../assets/img/MLZS/text-bg.png" class="text_bg" alt="">
                    <div class="text-white">
                        <p>Want to know about the exciting events at Mount Litera Zee School?</p>
                        <p>From academic milestones to vibrant extracurriculars, discover all about the latest updates and celebrations within our school community.</p>
                       </div>
                </div>

                <div class="home-demo mt-3">
                    <div class="owl-carousel owl-theme parent_owl">
                      <div class="item">
                        <div class="item_img_content">
                          <img src="../../../assets/img/MLZS/news1.png" alt="">
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                        
                          <h6>Zee Learn as the Most Trusted Education Brand</h6>
                          <p>8 MARCH 24</p>
                        </div>
                      </div>
                      <div class="item">
                        <div class="item_img_content">
                          <img src="../../../assets/img/MLZS/news2.png" alt="">
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                        
                          <h6>Mount Litera Zee School learning journey </h6>
                          <p>4 MAY 24</p>
                        </div>
                      </div>
                      <div class="item">
                        <div class="item_img_content">
                          <img src="../../../assets/img/MLZS/news3.png" alt="">
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                        
                          <h6>Mount Litera Zee School learning journey</h6>
                          <p>4 MAY 24</p>
                        </div>
                      </div>
                      <div class="item">
                        <div class="item_img_content">
                          <img src="../../../assets/img/MLZS/news3.png" alt="">
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                        
                          <h6>Zee Learn as the Most Trusted Education Brand</h6>
                          <p>8 MARCH 24</p>
                        </div>
                      </div>
                      <div class="item">
                        <div class="item_img_content">
                          <img src="../../../assets/img/MLZS/news3.png" alt="">
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                        
                          <h6>Zee Learn as the Most Trusted Education Brand</h6>
                          <p>8 MARCH 24</p>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div> -->

      <div class="row">
        <div class="col-md-6">
          <div class="title_content">
            <h2><b>What Our Parents
                <br>and Students Say</b></h2>
            <img src="../../../assets/img/MLZS/prg_text_bg.png" class="text_bg1" alt="">
            <img src="../../../assets/img/MLZS/prg_text_bg.png" class="text_bg" alt="">
            <p>Explore inspiring stories of our students, parents, and alumni, as they reflect on how their experiences
              at Mount Litera Zee School has made a lasting impact on their educational paths, personal opinions and
              long-term success.</p>
            <button class="btn read_mode mt-4">Know More <i class="fa fa-long-arrow-right mx-2"></i></button>

          </div>
        </div>
        <div class="col-md-6">
          <div class="fr_img_content">
            <div class="owl-carousel owl-theme vertical_carousel">
              <div class="item">
                <div class="row">
                  <div class="col-2 d-flex justify-content-center align-items-center">
                    <div class="item_img">
                      <img src="../../../assets/img/MLZS/fr_img1.png" alt="">
                    </div>
                  </div>

                  <div class="col-10">
                    <div class="item_text">
                      <p>“Mount Litera Zee Schools all across the country promise the children a new kind of education
                        that is based on latest research.”</p>
                      <h6>Mr Rohit Bihani</h6>
                      <span>01 June 2023</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="row">
                  <div class="col-2 d-flex justify-content-center align-items-center">
                    <div class="item_img">
                      <img src="../../../assets/img/MLZS/fr_2.png" alt="">
                    </div>
                  </div>

                  <div class="col-10">
                    <div class="item_text">
                      <p>“MLZS provided my child with a well-rounded education and academic excellence. Truly grateful!”</p>
                      <h6>Mrs Piya Patel</h6>
                      <span>31 March 2023</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="row">
                  <div class="col-2 d-flex justify-content-center align-items-center">
                    <div class="item_img">
                      <img src="../../../assets/img/MLZS/fr_3.png" alt="">
                    </div>
                  </div>

                  <div class="col-10">
                    <div class="item_text">
                      <p>“MLZS is a friendly place for kids. It builds a transparent bridge between parents and teachers. The teachers are like second parents for the kids.”</p>
                      <h6>P Srinivasa Rao</h6>
                      <span>05 May 2023</span>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          </div>

        </div>
      </div>

    </div>


    <div class="box">
      <app-social-wall></app-social-wall>
    </div>
  </div>
</section>