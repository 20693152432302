<section>
    <div class="mainDiv">
        <div class="banner_content">
        <img src="../../../assets/img/new-kidzee/dr_blue_bg.png" class="yello_bg_img" height="100%" alt="">
        <img src="../../../assets/img/new-kidzee/program_header_img.png" class="yello_bg_img_mobile" height="100%" alt="">
        <img src="../../../assets/img/new-kidzee/abcd.png" class="three_child" alt="">
        <img src="../../../assets/img/new-kidzee/left_img.png" class="left_img" alt="">
            <h1 class="whiteTitle">{{headerTitle}}</h1>
            <div class="subMenu_content">
                <app-sub-menu></app-sub-menu>
            </div>
            <img src="../.././../assets/img/new-kidzee/btrfly_img.png" class="btr_fly_img" alt="">
        </div>

        <div class="sub_content">
            <div class="row">
                <div class="col-md-12">
                    <div class="text_content">
                        <img src="../../../assets/img/new-kidzee/chocolate_img.png" class="chocolate_img" alt="">
                        <div class="paper_content">
                            <div class="infoContent mt-3">
                                <div class="text1">
                                    <div class="sub_text_black">Age Group</div>
                                    <div class="sub_text">1.5 - 2.5 Years</div>
                                </div>
                                <div class="text2">
                                    <div class="sub_text_black">Duration</div>
                                    <div class="sub_text">3 Hours/Day</div>
                                </div>
                            </div>
                        </div>
                        <img src="../../../assets/img/new-kidzee/bag_img.png" class="bag_img" alt="">
                        <div class="paraText mt-4">
                            <p>*As per RTE- Right to Education and NEP- National Education Policy from state to state.</p>
                            <p>Children in this age group as identified as <b>Toddlers</b>. Kidzee’s playgroup <b>age appropriate</b> curriculum plays an important role in helping children continue their exploration.
                            </p>
                            <p>At Kidzee, the Playgroup programme prepares a child for developmental and academic progress. </p>
                           <p>Playgroup curriculum provides a safe and stimulating environment for child to learn and improve in all developmental domains.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contentList">
            <div class="row">
                <div class="col-md-4">
                    <div class="listTitle">Key Areas in Playgroup Curriculum</div>
                    <div class="text_list">
                        <ul>
                            <li><span class="liText">Promoting positive peer social interaction</span></li>
                            <li><span class="liText">Opportunities for basic motor development</span></li>
                            <li><span class="liText">Exploration based learning</span></li>
                            <li><span class="liText">Math Readiness (Number Recognition)</span></li>
                            <li><span class="liText">Language Readiness (Letter Recognition)</span></li>
                           </ul>
                    </div>
                </div>
                <div class="col-md-4 kidzee_session">
                    <div class="listTitle">Kidzee Sessions</div>
                    <div class="text_list">
                        <ul>
                            <li><span class="liText">Circle Time</span></li>
                            <li><span class="liText">Free Play</span></li>
                            <li><span class="liText">Knowledge Time</span></li>
                            <li><span class="liText">Language Time</span></li>
                            <li><span class="liText">Numeracy Time</span></li>
                            <li><span class="liText">Talk Time</span></li>
                            <li><span class="liText">Outdoor</span></li>
                            <li><span class="liText">Indoor</span></li>
                            <li><span class="liText">Celebration of <br>festivals and special days</span></li>
                            <li><span class="liText">Field Trips</span></li>
                            <li><span class="liText">Puppet shows and skits</span></li>
                        </ul>
                    </div>

                </div>
                
    <div class="col-md-4">
        <div class="listTitle">Kidzee Special</div>
        <div class="text_list">
            <ul>
                <li><span class="liText">Artsy</span></li>
                <li><span class="liText">Personality Development</span></li>
                <li><span class="liText">Showstopper</span></li>
                <li><span class="liText">Mental Might</span></li>
                <li><span class="liText">Eco-conscious</span></li>
                <li><span class="liText">Tell-a-Tale</span></li>
                <li><span class="liText">Library</span></li>
                <li><span class="liText">Sciencify</span></li>
                <li><span class="liText">Whirl & Twirl</span></li>
                <li><span class="liText">Sensorium</span></li>
                <li><span class="liText">Critical Thinking - Problem solving</span></li>
                <li><span class="liText">Intergrated Sanskar </span></li>
                <li><span class="liText">Augmented Reality - Fantasy Box </span></li>
            </ul>
        </div>
    </div>
            </div>
        </div>
        <img src="../../../assets/img/new-kidzee/fruit_img.png" class="fruit_img" alt="">
        <img src="../../../assets/img/new-kidzee/mouse_img.png" class="mouse_img" alt="">
        <div class="faqs_content">
            <app-faqs></app-faqs>
        </div>
        <img src="../../../assets/img/new-kidzee/ptg_img.png" class="ptang_img" alt="">
        <img src="../../../assets/img/new-kidzee/fish_img.png" class="fish_img" alt="">
        <div class="blogs_content">
            <app-blogs></app-blogs>
        </div>
        <img src="../../../assets/img/new-kidzee/dall_img.png" class="dall_img" alt="">
    </div>
    <!-- <div class="bottomMonkey">
        <img srcset="../../../assets/img/monkey2.png" loading="lazy" class="monkeyImgList" alt="">
    </div> -->
</section>