<section>
    <div class="programmes_content">
        <div class="box">
            <div class="title_content text-center mt-5">
            <h2 class="text-center"><b>Programmes</b></h2>
            <img src="../../../assets/img/MLZS/prg_text_bg.png" class="text_bg" alt="">
            </div>
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Litera Foundational Years
                    <br><span>(3-5 Years)</span></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Litera Preparatory Years
                    <br><span>(6-8 Years)</span></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Litera Middle Years 
                    <br><span>(9-10 Years)</span></button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-secondary-tab" data-bs-toggle="pill" data-bs-target="#pills-secondary" type="button" role="tab" aria-controls="pills-secondary" aria-selected="false">Litera Secondary Years 
                        <br><span>(11-13 Years)</span></button>
                  </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div class="program_tab_content">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="programm_text_content">
                                    <p>(3-5 Years)</p>
                                    <p>Litera Foundational Years</p>
                                    <p>The Pre-Primary School Program at Mount Litera Zee School, covering Nursery, Junior Kindergarten (Jr. KG), and Senior Kindergarten (Sr. KG), lays a strong foundation for lifelong learning. This early stage focuses on nurturing curiosity, social skills, and cognitive abilities, helping young children 'learn to learn' in an engaging and supportive environment. Our program combines play-based and structured activities, promoting holistic development and preparing children for a smooth transition to primary school.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="programm_img_content w-100 d-flex justify-content-center">
                                    <img src="../../../assets/img/MLZS/programm_img.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div class="program_tab_content">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="programm_text_content">
                                    <p>(6-8 Years)</p>
                                    <p>The Litera Preparatory at Mount Litera Zee School is designed to connect learning with the real world, helping children become active participants in their educational journey. Focusing on each child's unique potential, both inside and outside the classroom, the program builds a strong foundation for future learning. From Class 1 onwards, we adopt the Litera Nova approach, fostering creativity, critical thinking, and holistic development to prepare children for the challenges ahead.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="programm_img_content w-100 d-flex justify-content-center">
                                    <img src="../../../assets/img/MLZS/pg-2.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <div class="program_tab_content">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="programm_text_content">
                                    <p>(9-10 Years)</p>
                                    <p>The Litera Middle School Program at Mount Litera Zee School focuses on fostering intellectual and social growth as students begin to connect with the broader community. Using the Litera Nova approach, we provide an integrated teaching, learning, and assessment framework that encourages independent learning and personal responsibility. This stage aims to help
                                        students connect meaningful life experiences with their academic studies, empowering them to see the relevance of what they learn in the world around them.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="programm_img_content w-100 d-flex justify-content-center">
                                    <img src="../../../assets/img/MLZS/pg3.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-secondary" role="tabpanel" aria-labelledby="pills-secondary-tab">
                    <div class="program_tab_content">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="programm_text_content">
                                    <p>(11-13 Years)</p>
                                    <p>The Secondary School Program at Mount Litera Zee School focuses on shaping the future of each student based on their subject preferences and developed skills. At this stage, the goal is to refine and deepen the knowledge and skills acquired in Middle School, preparing students for higher education and future careers. Through focused learning and personalised guidance, we help students build a strong foundation for success in their academic and professional journeys.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="programm_img_content w-100 d-flex justify-content-center">
                                    <img src="../../../assets/img/MLZS/pg4.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             
            </div>
        </div>
    </div>
</section>