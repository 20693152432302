import { Component } from '@angular/core';

@Component({
  selector: 'app-programmes-main-page',
  templateUrl: './programmes-main-page.component.html',
  styleUrls: ['./programmes-main-page.component.css']
})
export class ProgrammesMainPageComponent {

}
