<section>
    <div class="mainDiv">
        <div class="banner_content">
            <img src="../../../assets/img/new-kidzee/dr_blue_bg.png" class="yello_bg_img" height="100%" alt="">
            <img src="../../../assets/img/new-kidzee/program_header_img.png" class="yello_bg_img_mobile" height="100%" alt="">
            <img src="../../../assets/img/new-kidzee/abcd.png" class="three_child" alt="">
            <img src="../../../assets/img/new-kidzee/left_img.png" class="left_img" alt="">
                <h1 class="whiteTitle">{{headerTitle}}</h1>
                <div class="subMenu_content">
                    <app-sub-menu></app-sub-menu>
                </div>
                <img src="../.././../assets/img/new-kidzee/btrfly_img.png" class="btr_fly_img" alt="">
            </div>
        <div class="sub_content">
                <div class="row flex-column-reverse flex-lg-row">
                    <!-- <div class="col-md-5">
                        <div class="dayCardImg">
                            <div class="">
                                <img srcset="../../../assets/img/dayCare_child.png" loading="lazy" alt="" class="schoolGirlImg responsive"
                                    align="center">
                            </div>
                        </div>
                    </div> -->
                    <div class="col-md-12">

                        <div class="dayCareText">
                            <div class="right_content1">
                                <div class="listTitle">
                                    A home away from home for your child!
                                </div>
                                <div class="paragraph_content mt-2">
                                    <p>We understand that the early years in the child’s life are important for the overall development. The primary role of parents in these years is to provide their children with an environment that is  loving, caring, fun-filled and one which provides happy experiences. As these aspects play a very important role in fostering the developmental areas, like, cognitive development, physical development, socio-emotional development, creative development , and language development. Our objective is to create a home away from home for them to feel safe, secure and happy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kizeeList">
<div class="row">
    <div class="listTitle">Take a walkthrough of a day at Kidzee Daycare Facility:</div>
    <div class="col-md-6">
       
        <div class="text_list">
            <ul>

                <li><span class="liText">Freshen up</span></li> 
                <li><span class="liText">Lunch</span></li> 
                <li><span class="liText">Nap time</span></li>
                <li><span class="liText">Academics and academic assitance.(Home work)</span></li>
                <li><span class="liText">Inter-intra personal skills</span></li>

                                </ul>
        </div>
</div>
<div class="col-md-6">
       
    <div class="text_list">
        <ul>
                <li><span class="liText">Indoor activities</span></li>
                <li><span class="liText">Snacks</span></li>
                <li><span class="liText">Outdoor Activities</span></li>
                <li><span class="liText">Edutainment</span></li> 
        </ul>
    </div>
</div>
</div>
</div>
        </div>

        <div class="dayCareImg_content mt-5">
            <div class="subTitle">Take a walkthrough of a day at Kidzee DayCare Facility</div>
            <div class="dayCareImgContent">
                <img src="../../../assets/img/new-kidzee/walk-img.png" loading="lazy" class="dayCareImg" alt="">
            </div>
        </div>
        <div>
     <app-faqs></app-faqs>

        </div>
        </div>
        </section>
