<section>
    <div class="about_content">
    <div class="box">
        <div class="row">
            <div class="col-md-6">
                <div class="title_content">
                    <h2 class="text-white"><b>About Us</b></h2>
                    <img src="../../../assets/img/MLZS/text-bg.png" class="text_bg" alt="">
                    <div class="text-white">
                        <p>Mount Litera Zee School is one of the leading and fastest-growing chains of K-12 schools in India. Started under the pioneering leadership of Zee Learn Ltd., an education giant powered by the Essel Group, Mount Litera Zee School is on a mission to redefine the education landscape in the country.</p>
                        <button class="btn read_mode">Know More <i class="fa fa-long-arrow-right mx-2"></i></button>
                    </div>
                </div>

            </div>
            <div class="col-md-6">
                <div class="about_img_content w-10 d-flex justify-content-center">
                <img src="../../../assets/img/MLZS/about_img.png" alt="">
            </div>
            </div>
        </div>
        <div class="counter_box mt-5">
            <div class="row">
                <div class="col-md-3">
                    <div class="counter_content">
                        <div class="num">
                            <h2 class="text-white text-center"><b>115+</b></h2>
                        </div>
                        <div class="counter_text text-center text-white">
                            <p>Number of schools</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="counter_content">
                        <div class="num">
                            <h2 class="text-white text-center"><b>18+</b></h2>
                        </div>
                        <div class="counter_text text-center text-white">
                            <p>Presence in number of states</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="counter_content">
                        <div class="num">
                            <h2 class="text-white text-center"><b>4,295+</b></h2>
                        </div>
                        <div class="counter_text text-center text-white">
                            <p>Number of educators</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="counter_content">
                        <div class="num">
                            <h2 class="text-white text-center"><b>56,000+</b></h2>
                        </div>
                        <div class="counter_text text-center text-white">
                            <p>Number of students nurtured</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>