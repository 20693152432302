<footer class="desktopResponsive">
    <div class="footerContent">
        <div class="box">
        <div class="row py-5">
            <div class="col-md-6">
                <div class="footer_left">
                    <img src="../../../../assets/img/MLZS/footer/zeeLearn.png" alt="">
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-4">
            <div class="subListContent">
                <ul>
                    <li>Programme</li>
                    <li>Curriculum</li>
                    <li>Academics</li>
                </ul>
            </div>
        </div>
        <div class="col-md-4">
            <div class="subListContent">
                <ul>
                    <li>About Us</li>
                    <li>Our Presence</li>
                    <li>News & Event</li>
                </ul>
            </div>
        </div>
        <div class="col-md-4">
            <div class="subListContent">
                <ul>
                    <li>For Admission</li>
                    <li>For Franchise</li>
                    <li>Contact Us</li>
                </ul>
            </div>
        </div>
        </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <img src="../../../../assets/img/MLZS/footer/logo_group.png" width="100%" alt="">
                </div>
                <div class="col-md-6">
                 <div class="right_text">
                    <div class="media_icon">
                        <img src="../../../../assets/img/MLZS/footer/facebook.png" alt="">
                        <img src="../../../../assets/img/MLZS/footer/twitter.png" alt="">
                        <img src="../../../../assets/img/MLZS/footer/youtube.png" alt="">
                        <img src="../../../../assets/img/MLZS/footer/in.png" alt="">
                    </div>
                    <div>
                    <p class="text-white mt-3">Terms of Use | Privacy Policy | Disclaimer</p>
                    <p class="text-white">Copyright 2024 Zee Learn Ltd. All rights reserved</p>
                </div>
                 </div>
                </div>
            </div>
        </div>
       
        <!-- <div class="row">
            <div class="col-md-12">
                <div class="text-white text-center footerTextEmailId">
                    Copyright © Zee Learn Ltd. All rights reserved.
                </div>
            </div>
        </div> -->
    </div>
    </div>

    <!-- <div class="btn_header" id="footer_btn">
        <div class="btn_content">
            <div class="fb_insta_icon_content">

             <a href="https://www.facebook.com/KidzeeIndia" target="_blank"> <i class="fa fa-facebook media_icon"></i></a>
             <a href="https://www.instagram.com/kidzeeindia/" target="_blank">
                <i class="fa fa-instagram media_icon"></i>
            </a>
            </div>
            <div class="btn_div d-flex">
                <div class="d-flex justify-content-center align-items-center  px-2">
                    <img src="../../../../assets/img/new-kidzee/admission_icon.png" width="25px" height="30px" alt="">
            <button class="white_btn brder_right" routerLink="/admissions">Admission Enquiry</button>
        </div>
        <div class="d-flex justify-content-center align-items-center px-2">
            <img src="../../../../assets/img/new-kidzee/start_icon.png" width="25px" height="30px" alt="">
            <button class="white_btn" routerLink="/partner-with-kidzee">Start a Franchise</button>
        </div>
        </div>
        </div>

    </div>
    <a href="#" class="scrollToTop" id="scroll_Top_btn">
        <button class="btn scroll_btn"><i class="fa fa-chevron-up"></i></button></a> -->
</footer>

<footer class="mobileResponsive">
    <div class="footerContent">
        <div class="row">
            <div class="col-md-4">
                <div class="footer_left">
                    <div class="footerLogo"></div>
                    <div class="detailsContent">
                        <div class="zeeLearnText mt-3">Zee Learn Limited </div>
                        <div class="adress mt-2">135, Continental Building,B wing, Worli, Mumbai,Maharashtra 400 018.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="footerTextContnetn">
                    <hr>
                    <div class="subListContent">
                        <div class="row">
                            <div class="col-md-4">
                                <ul>
                                    <li routerLink="/playgroup">PlayGroup</li>
                                    <li routerLink="/nursery">Nursery</li>
                                    <li routerLink="/kindergarten">Kindergarten</li>
                                    <li routerLink="/teacher-training-programme">Teacher Training Programme</li>
                                    <li routerLink="/PrivacyPolicy">Privacy Policy</li>
                                </ul>
                            </div>
                            <hr>
                            <div class="col-md-4">
                                <ul>
                                    <li routerLink="/Daycare">DayCare</li>
                                    <li routerLink="/pentemind">Péntemind</li>
                                     <li routerLink="/blog/all">Blog</li>
                                     <li routerLink="/innews">In News</li>
                                    <li routerLink="locateUs">Locate Us</li>
                                   </ul>
                            </div>
                            <div class="col-12">
                                <div class="youtube_video">
                                    <div onclick="vtIframeEnquiry(this, 'https://www.youtube.com/embed/f0-9CI97fCY', 10)" class="testimonial_profile_img" data-form="lg" data-title="Site Video" data-btn="Continue Tour" data-enquiry="Zica Site Tour" data-redirect="virtualtour" data-toggle="modal" data-target="#enqModal">
                                        <iframe frameborder="0" class="d-video" allowfullscreen="" sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation" src="https://www.youtube.com/embed/f0-9CI97fCY?autoplay=1&amp;rel=0&amp;controls=0&amp;"
                                                width="100%"></iframe>
                                    </div>
                                   </div>
                            </div>
                            <hr>
                            <div class="col-md-4">
                                <ul class="ul_content">
                                    <li>Contact US</li>
                                    <li class="mt-2">
                                        <div class="icon_content">
                                            <a href="tel:91 93200 63100" class="d-flex"><div class="phone"></div><span class="details_text"> +91 93200 63100</span></a>
                                        </div>
                                    </li>
                                    <li class="mt-2">
                                        <div class="icon_content">
                                            <div class="email"></div><span class="details_text">
                                                care&#64;kidzee.com</span>
                                        </div>
                                    </li>
                                    <li class="mt-2">
                                        <div class="icon_content">
                                            <div class="time"></div><span class="details_text"> 10:00 a.m. to 06:00
                                                p.m., Mon-Fri</span>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-white text-center footerTextEmailId">
                    Copyright © Zee Learn Ltd. All rights reserved.
                </div>
            </div>
            <div class="col-dm-12">
                <div class="fb_insta_icon_content">

                    <!-- <a href="https://www.facebook.com/KidzeeIndia" target="_blank"> <i class="fa fa-facebook media_icon"></i></a>
                    <a href="https://www.instagram.com/kidzeeindia/" target="_blank"><i class="fa fa-instagram media_icon"></i></a> -->
                   
                    <div class="btn_div d-flex">
                        <div class="d-flex justify-content-center align-items-center  px-2">
                            <img src="../../../../assets/img/new-kidzee/admission_icon.png" width="25px" height="30px" alt="">
                    <button class="white_btn brder_right" routerLink="/admissions">Admission Enquiry</button>
                </div>
                <div class="d-flex justify-content-center align-items-center px-2">
                    <img src="../../../../assets/img/new-kidzee/start_icon.png" width="25px" height="30px" alt="">
                    <button class="white_btn" routerLink="/partner-with-kidzee">Start a Franchise</button>
                </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    <div class="btn_header" id="footer_btn1">
        <!-- <div class="btn_content">
            <button class="white_btn" routerLink="/admissions">Admission Enquiry</button>
            <button class="white_btn" routerLink="/partner-with-kidzee">Start a Franchise</button>
        </div> -->
      
    </div>

    <a href="#" class="scrollToTop" id="scroll_Top_btn">
        <button class="btn scroll_btn"><i class="fa fa-chevron-up"></i></button></a>
</footer>
