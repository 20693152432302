<section>
    <div class="locat_school mt-5">
        <div class="box">
            <div class="title_content text-center"> 
            <h2><b>Locate A School</b></h2>
            <img src="../../../assets/img/MLZS/prg_text_bg.png" class="text_bg" alt="">

            <div class="row">
                <div class="col-md-6">
                    <div class="locat_form">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                <select name="" id="" class="form-control">
                                    <option value="">School Category</option>
                                </select>
                                <i class="fa fa-angle-down"></i>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mt-3">
                                <select name="" id="" class="form-control">
                                    <option value="">Select State</option>
                                </select>
                                <i class="fa fa-angle-down"></i>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mt-3">
                                <select name="" id="" class="form-control">
                                    <option value="">Select City</option>
                                </select>
                                <i class="fa fa-angle-down"></i>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mt-3">
                                <select name="" id="" class="form-control">
                                    <option value="">Select Location</option>
                                </select>
                                <i class="fa fa-angle-down"></i>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mt-3">
                                <select name="" id="" class="form-control">
                                    <option value="">Select Location</option>
                                </select>
                                <i class="fa fa-angle-down"></i>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mt-4">
                               <button class="btn reset_btn">Recet <i class="fa fa-long-arrow-right mx-2"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="map_content">
                        <img src="../../../assets/img/MLZS/map_img.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>


    <div class="locat_school mt-5">
        <div class="box">

            <div class="row">
                <div class="col-md-6">
                    <div class="title_content"> 
                        <h2><b>Get In Touch <br>With Us</b></h2>
                        <img src="../../../assets/img/MLZS/prg_text_bg.png" class="text_bg1" alt="">
                    </div>
                   <p>Feel free to contact us anytime, and we’ll get back to you as soon as possible. 
                    Your inquiries are important to us, and we look forward to connecting with you!
                    </p>
                    <div class="info_content mt-3">
                        <div class="row">
                            <div class="col-1">
                                <img src="../../../assets/img/MLZS/home.png" class="media_icon" alt="">
                            </div>
                            <div class="col-11">
                                <p><b>Our Location</b></p>
                                <p>Zee Learn Limited,135, Continental Building,Dr. Annie Besant Road, Worli Mumbai - 400 018,Maharashtra.</p>
                            </div>
                            <div class="col-1">
                                <img src="../../../assets/img/MLZS/call.png" class="media_icon" alt="">
                            </div>
                            <div class="col-11">
                                <p><b>Phone Number</b></p>
                                <p>93200 63100</p>
                            </div>
                            <div class="col-1">
                                <img src="../../../assets/img/MLZS/mail.png" class="media_icon" alt="">
                            </div>
                            <div class="col-11">
                                <p><b>Email Address</b></p>
                                <p>partner&#64;mountlitera.com</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="locat_form">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                               <input type="text" class="form-control" placeholder="Your Name" name="" id="">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mt-3">
                                    <input type="text" class="form-control"  placeholder="Your Email" name="" id="">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mt-3">
                                    <input type="text" class="form-control"  placeholder="Your Phone" name="" id="">
                                </div>
                            </div>
                            <div class="col-md-12 mt-3">
                                <textarea name="" id="" class="w-100"  placeholder="Your Massage"></textarea>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mt-4">
                               <button class="btn reset_btn">Recet <i class="fa fa-long-arrow-right mx-2"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
</section>