<section>
    <div class="about_content">
    <div class="box">
        <div class="row">
            <div class="col-md-12">
                <div class="title_content text-center">
                    <h2 class="text-white"><b>Testimonials</b></h2>
                    <img src="../../../assets/img/MLZS/text-bg.png" class="text_bg" alt="">
                    <div class="text-white text-center">
                        <p>Explore inspiring stories of our students, parents, and alumni, as they reflect on how their experiences at Mount</p>
                        <p>Litera Zee School has made a lasting impact on their educational paths, personal opinions and long-term success.</p>
                       </div>
                </div>
                <div class="home-demo mt-3">
                    <div class="owl-carousel owl-theme news_owl" id="">
                      <div class="item">
                        <div class="item_img_content">
                            <iframe class="text_bg" src="https://www.youtube.com/embed/-yyhXOOUzJw" title="Chiv Chiv Chimni in 3D - Marathi 3D Rhymes | Marathi Balgeet Song मराठी गाणी 2019" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                        <img src="../../../assets/img/MLZS/Quote.png" alt="">
                          <h6 class="mt-2">Hear from our parent at Mount Litera Zee School.</h6>
                          <!-- <p>8 MARCH 24</p> -->
                        </div>
                      </div>
                      <div class="item">
                        <div class="item_img_content">
                            <iframe class="text_bg" src="https://www.youtube.com/embed/-yyhXOOUzJw" title="Chiv Chiv Chimni in 3D - Marathi 3D Rhymes | Marathi Balgeet Song मराठी गाणी 2019" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                            <img src="../../../assets/img/MLZS/Quote.png" alt="">
                          <h6 class="mt-2">Hear from our parent at Mount Litera Zee School.</h6>
                          <!-- <p>4 MAY 24</p> -->
                        </div>
                      </div>
                      <div class="item">
                        <div class="item_img_content">
                            <iframe class="text_bg" src="https://www.youtube.com/embed/-yyhXOOUzJw" title="Chiv Chiv Chimni in 3D - Marathi 3D Rhymes | Marathi Balgeet Song मराठी गाणी 2019" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                            <img src="../../../assets/img/MLZS/Quote.png" alt="">
                          <h6 class="mt-2">Hear from our parent at Mount Litera Zee School.</h6>
                          <!-- <p>4 MAY 24</p> -->
                        </div>
                      </div>
                      <div class="item">
                        <div class="item_img_content">
                            <iframe class="text_bg" src="https://www.youtube.com/embed/-yyhXOOUzJw" title="Chiv Chiv Chimni in 3D - Marathi 3D Rhymes | Marathi Balgeet Song मराठी गाणी 2019" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                            <img src="../../../assets/img/MLZS/Quote.png" alt="">
                          <h6 class="mt-2">Zee Learn as the Most Trusted Education Brand</h6>
                          <!-- <p>8 MARCH 24</p> -->
                        </div>
                      </div>
                      <div class="item">
                        <div class="item_img_content">
                            <iframe class="text_bg" src="https://www.youtube.com/embed/-yyhXOOUzJw" title="Chiv Chiv Chimni in 3D - Marathi 3D Rhymes | Marathi Balgeet Song मराठी गाणी 2019" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <hr>
                        <div class="item_text mt-2">
                            <img src="../../../assets/img/MLZS/Quote.png" alt="">
                          <h6 class="mt-2">Zee Learn as the Most Trusted Education Brand</h6>
                          <!-- <p>8 MARCH 24</p> -->
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
       
    </div>
</div>
</section>