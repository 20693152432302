<section>
    <div class="mainDiv">
        <div class="legancy_content">
            <div class="desk_img">
            <img src="../../../assets/img/new-kidzee/banner/our_presence.png" width="100%" class="our_presence_bg" alt="">
        </div>
        <div class="res_img">
            <img src="../../../assets/img/new-kidzee/banner/city_bg_img.png" width="100%" class="our_presence_bg" alt="">
        </div>
            <div class="form_box">
             <div class="row">
                <div class="col-md-6">
                    
                </div>
                <div class="col-md-6">          
                    <app-partneradmission [cityname]="headerTitle"></app-partneradmission>
                 </div>
             </div>
           </div>
            <h1 class="whiteTitle">Best Preschool in <br>{{headerTitle}}</h1>
            <div class="subMenu_content_admission">
                <app-sub-menu></app-sub-menu>
            </div>
        </div>
        <h1 class="title text-center">Best Preschool & Kindergarten in Ahmedabad</h1>
        <div class="d-flex justify-content-center">
        <img src="../../../assets/img/new-kidzee/bottom_img.png" class="bottom_img" alt="">
    </div>
        <div class="count_content desk_resp_count">
            <div class="row">
                <div class="col-md-3">
                    <div class="box">
                        <div class="count_num">
                            <div class="num_count">100%</div>
                            <div class="label">SUPPORT</div>
                        </div>
                    <img src="../../../assets/img/new-kidzee/support.png" width="80%" alt="">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box">
                        <div class="count_num">
                            <div class="num_count">2,000+</div>
                            <div class="label">PRESCHOOLS</div>
                        </div>
                    <img src="../../../assets/img/new-kidzee/ka1.png" width="80%" alt="">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box">
                        <div class="count_num">
                            <div class="num_count">750+</div>
                            <div class="label">CITIES</div>
                        </div>
                    <img src="../../../assets/img/new-kidzee/k2.png" width="80%" alt="">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box">
                        <div class="count_num">
                            <div class="num_count">750+</div>
                            <div class="label">ELEMENTS IN <br>STARTER KIT</div>
                        </div>
                    <img src="../../../assets/img/new-kidzee/k3.png" width="80%" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="numb_list_mob">
            <div id="carouselExampleIndicators1" class="carousel slide">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="0" class="active"
                        aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="2"
                        aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="3"
                        aria-label="Slide 4"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="box">
                            <div class="count_num">
                                <div class="num_count">100%</div>
                                <div class="label">SUPPORT</div>
                            </div>
                        <img src="../../../assets/img/new-kidzee/support.png" width="80%" alt="">
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="box">
                            <div class="count_num">
                                <div class="num_count">2,000+</div>
                                <div class="label">PRESCHOOLS</div>
                            </div>
                        <img src="../../../assets/img/new-kidzee/ka1.png" width="80%" alt="">
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="col-md-3">
                            <div class="box">
                                <div class="count_num">
                                    <div class="num_count">750+</div>
                                    <div class="label">CITIES</div>
                                </div>
                            <img src="../../../assets/img/new-kidzee/k2.png" width="80%" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="box">
                            <div class="count_num">
                                <div class="num_count">750+</div>
                                <div class="label">ELEMENTS IN <br>STARTER KIT</div>
                            </div>
                        <img src="../../../assets/img/new-kidzee/k3.png" width="80%" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>


     <img src="../../../assets/img/new-kidzee/fruit_img.png" class="fruit_img" alt="">
       <div class="ourPrograms_content">
        <!-- <app-programmes-list></app-programmes-list> -->
       </div>
       <div class="euroKids_content mt-5">
        <div class="euroKids_title text-left">Welcome to Kidzee Preschool in {{headerTitle}}</div>
        <div class="paragraph_content text-white mt-3">
            <p>Are you searching for the best preschool in {{headerTitle}} that provides a nurturing and enriching
                environment for your child's early education journey? Look no further than Kidzee Preschool. With a
                legacy spanning over 21 years and a network of 1400+ schools across three countries, Kidzee is a
                renowned and established brand that stands out for its child-centric approach to education.</p>
            </div>
            <div class="euroKids_title text-left">Why Choose Kidzee Preschool in {{headerTitle}}?</div>
        <div class="paragraph_content text-white mt-3">
            <p>At Kidzee Preschool in {{headerTitle}}, we understand the importance of early childhood education and the
                role it plays in shaping a child's future. Here are the prime reasons why Kidzee is the leading choice for
                parents seeking the best for their little ones:</p>
            </div>

            <div class="desk_content">

            <div class="row mt-5">
                <div class="col-md-1">
                    <img src="../../../assets/img/new-kidzee/hol.png" alt="">
                </div>
                <div class="col-md-11">
                    <div class="euroKids_title text-left">Holistic Learning</div>
        <div class="paragraph_content text-white">
            <p>At Kidzee, we believe in nurturing every aspect of a child's development. Our holistic approach to
                learning focuses on intellectual, physical, emotional, and social development, ensuring that your child
                receives a well-rounded education that prepares them for success in the future.</p>
        </div>
                </div>
            </div>

        <div class="row" *ngIf="readMoreContent">
            <div class="col-md-1">
                <img src="../../../assets/img/new-kidzee/exp.png" alt="">
            </div>
            <div class="col-md-11">
                <div class="readMoreCOntent">
                    <div class="euroKids_title text-left">Expert Educators</div>
                <div class="paragraph_content text-white">
                    <p>Our team of experienced and qualified educators is dedicated to providing the highest quality of care
                        and education for your child. With their expertise and passion for teaching, they create a supportive and
                        engaging learning environment where children can thrive and reach their full potential.</p>
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <img src="../../../assets/img/new-kidzee/nl.png" alt="">
        </div>
        <div class="col-md-11">
            <div class="euroKids_title text-left">Innovative Learning</div>
            <div class="paragraph_content text-white">
                <p>Kidzee Preschool in {{headerTitle}} offers an innovative curriculum that is designed to foster curiosity,
                    creativity, and critical thinking skills in young minds. Through hands-on activities, interactive lessons, and
                    exploration-based learning experiences, we inspire a love for learning that lasts a lifetime.</p>
            </div>
        </div>

        <div class="col-md-1">
        </div>
        <div class="col-md-11">
            <div class="euroKids_title text-left">Smooth Transition</div>
            <div class="paragraph_content text-white">
                <p>We understand that transitioning from preschool to primary school can be a significant milestone for
                    both children and parents. At Kidzee, we prepare children for this transition by equipping them with the
                    necessary skills, confidence, and independence to thrive in a new environment seamlessly.</p>
           </div>
        </div>

        <div class="col-md-1"></div>
        <div class="col-md-11">
            <div class="euroKids_title text-left">Parental Involvement</div>
       <div class="paragraph_content text-white">
        <p>We believe that parental involvement plays a crucial role in a child's education journey. That's why we
            encourage parents to be actively involved in their child's learning experience through regular
            communication, parent-teacher meetings, and involvement in school activities and events.</p>
    </div>
        </div>

        <div class="col-md-1"></div>
        <div class="col-md-11">
            <div class="euroKids_title text-left">Safe and Secure</div>
            <div class="paragraph_content">
             <p>Safety and security are our top priorities at Kidzee Preschool in {{headerTitle}}. We have stringent safety
                 measures in place to ensure the well-being of every child, including secure premises, trained staff, and
                 supervision at all times. You can have peace of mind knowing that your child is in safe hands with us.</p>
                 <p>Enhance Your Child&#39;s Early Education Journey with Kidzee Preschool in (City Name)
                     Join us at Kidzee Preschool in (City Name) and give your child the best start in life. Experience the Kidzee
                     difference and watch your child grow, learn, and thrive in a supportive and nurturing environment.</p>
                     <p>Contact us today to schedule a visit and learn more about our programs.</p>
          
         </div>
</div>
</div>
</div>


<div class="mob_res_content">
    <div id="carouselExampleIndicators11" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators11" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators11" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators11" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active carousel_item_res">
            <div class="row mt-5">
                <div class="col-md-1 d-flex justify-content-center">
                    <img src="../../../assets/img/new-kidzee/hol.png" alt="">
                </div>
                <div class="col-md-11">
                    <div class="euroKids_title text-left">Holistic Learning</div>
        <div class="paragraph_content text-white">
            <p>At Kidzee, we believe in nurturing every aspect of a child's development. Our holistic approach to
                learning focuses on intellectual, physical, emotional, and social development, ensuring that your child
                receives a well-rounded education that prepares them for success in the future.</p>
        </div>
                </div>
            </div>
          </div>
          <div class="carousel-item carousel_item_res">
            <div class="row">
                <div class="col-md-1 d-flex justify-content-center">
                    <img src="../../../assets/img/new-kidzee/exp.png" alt="">
                </div>
                <div class="col-md-11">
                    <div class="readMoreCOntent">
                        <div class="euroKids_title text-left">Expert Educators</div>
                    <div class="paragraph_content text-white">
                        <p>Our team of experienced and qualified educators is dedicated to providing the highest quality of care
                            and education for your child. With their expertise and passion for teaching, they create a supportive and
                            engaging learning environment where children can thrive and reach their full potential.</p>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="carousel-item carousel_item_res">
            <div class="col-md-1 d-flex justify-content-center">
                <img src="../../../assets/img/new-kidzee/nl.png" alt="">
            </div>
            <div class="col-md-11">
                <div class="euroKids_title text-left">Innovative Learning</div>
                <div class="paragraph_content text-white">
                    <p>Kidzee Preschool in {{headerTitle}} offers an innovative curriculum that is designed to foster curiosity,
                        creativity, and critical thinking skills in young minds. Through hands-on activities, interactive lessons, and
                        exploration-based learning experiences, we inspire a love for learning that lasts a lifetime.</p>
                </div>
            </div>
          </div>

          <div class="carousel-item carousel_item_res">
            <div class="col-md-1 d-flex justify-content-center">
            </div>
            <div class="col-md-11">
                <div class="euroKids_title text-left">Smooth Transition</div>
                <div class="paragraph_content text-white">
                    <p>We understand that transitioning from preschool to primary school can be a significant milestone for
                        both children and parents. At Kidzee, we prepare children for this transition by equipping them with the
                        necessary skills, confidence, and independence to thrive in a new environment seamlessly.</p>
               </div>
            </div>
          </div>

          <div class="carousel-item carousel_item_res">
            <div class="col-md-1 d-flex justify-content-center"></div>
        <div class="col-md-11">
            <div class="euroKids_title text-left">Parental Involvement</div>
       <div class="paragraph_content text-white">
        <p>We believe that parental involvement plays a crucial role in a child's education journey. That's why we
            encourage parents to be actively involved in their child's learning experience through regular
            communication, parent-teacher meetings, and involvement in school activities and events.</p>
    </div>
        </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators11" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators11" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
</div>

        <div class="btn_div d-flex justify-content-start mt-3">
            <button class="btn btn_readMore" *ngIf="readMore" (click)="showContent(1)">Read More</button>
            <button class="btn btn_readLess" *ngIf="readLess" (click)="showContent(2)">Read Less</button>
        </div>
       </div> 

       <img src="../../../assets/img/new-kidzee/circal.png" class="circal_img" alt="">
       <img src="../../../assets/img/new-kidzee/shift.png" class="shift_img" alt="">
       <div class="centers_content mt-5">
        <img src="../../../assets/img/new-kidzee/scale.png" class="scale_img" alt="">
        <app-centers-in-agartala [cityname]="headerTitle"></app-centers-in-agartala>
       </div>
      
       <div class="faqs_content">
        <app-faqs></app-faqs>
       </div>
    </div>
</section>