import { Component } from '@angular/core';
declare var $: any;  // Declare jQuery
@Component({
  selector: 'app-programmes',
  templateUrl: './programmes.component.html',
  styleUrls: ['./programmes.component.css']
})
export class ProgrammesComponent {

  ngAfterViewInit(): void {
  setTimeout(()=>{
    $('#carousel2').owlCarousel({
      items:1,
      margin: 20,
      loop: true,
      autoplay: true,
      autoplayTimeout: 2500,
      nav: false,
      dots: true
    });
  },2000)

}
}
