<section>
    <div class="sec_box_content">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-2">
                <div class="box_content">
                    <div class="img_box text-center">
                        <img src="../../../assets/img/MLZS/apple.png" alt="">
                    </div>
                    <div class="title_content text-center mt-2">
                        <p>India's Best</p>
                        <p>Pre-Primary School</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="box_content">
                    <div class="img_box text-center mt-3">
                        <img src="../../../assets/img/MLZS/book.png" alt="">
                    </div>
                    <div class="title_content text-center mt-2">
                        <p>India's Best</p>
                        <p>Primary School</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="box_content">
                    <div class="img_box text-center mt-3">
                        <img src="../../../assets/img/MLZS/read_book.png" alt="">
                    </div>
                    <div class="title_content text-center mt-2">
                        <p>India's Best</p>
                        <p>Middle School</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="box_content">
                    <div class="img_box text-center mt-3">
                        <img src="../../../assets/img/MLZS/pen.png" alt="">
                    </div>
                    <div class="title_content text-center mt-2">
                        <p>Secondary School</p>
                        <p>Education</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="box_content">
                    <div class="img_box text-center mt-2">
                        <img src="../../../assets/img/MLZS/scopy.png" alt="">
                    </div>
                    <div class="title_content text-center mt-2">
                        <p>India's Best</p>
                        <p>Secondary</p>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
      </div>
    <div class="banner_content">
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
        <!-- <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div> -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../../../assets/img/MLZS/banner/create_banner.png" class="d-block" alt="admission_banner">
            <div class="carousel-caption d-none d-md-block">
              <h2 class="admission_text"><b>I Want to Create</b></h2>
              <h2 class="year_text"><b>India's Google.</b></h2>
              <p>I am a Mount Literan.</p>
            </div>
          </div>
        </div>
        <!-- <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> -->
      </div>
      
    </div>
    <div class="main_bg">
 <div class="box_content">
    <app-about-us></app-about-us>
 </div>
 <div class="box_content">
    <app-programmes></app-programmes>
 </div>
 <div class="box_content">
    <app-franchise-opportunity></app-franchise-opportunity>
    </div>
 <div class="box_content">
    <app-news></app-news>
 </div>
 <div class="box_content">
    <app-our-parents></app-our-parents>
</div>
<div class="box_content">
    <app-blogs></app-blogs>
</div>
</div>
<div class="box_content">
    <app-faqs></app-faqs>
    </div>
    <div class="box_content">
        <app-locate-us></app-locate-us>
    </div>
 </section>