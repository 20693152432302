<section>
    <div class="mainDiv">
        <div class="banner_content">
            <div class="whiteTitle">{{headerTitle}}</div>
        </div>
      <div class="subContent">
        <div class="para_content">
            <p>THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF INFORMATION TECHNOLOGY ACT, 2000 AND RULES THERE UNDER AS APPLICABLE AND THE AMENDED PROVISIONS PERTAINING TO ELECTRONIC RECORDS IN VARIOUS STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.</p>
    
            <h4 class="sub_title mt-4 mb-4">COLLECTION AND USE OF PERSONAL INFORMATION</h4>
            <p>When you use the Website, we collect and store your personal information which is provided by you from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience. This allows us to provide services and features that most likely meet your needs, and to customize our Website to make your experience safer and easier. More importantly, while doing so we collect personal information from you that we consider necessary for achieving this purpose.</p>
            <p>In general, you can browse the Website without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. Where possible, we indicate which fields are required and which fields are optional. You always have the option to not provide information by choosing not to use a particular service or feature on the Website. ZLL and its affiliates may share this personal information with each other and use it consistent with this Privacy Policy. We may automatically track certain information about you based upon your behaviour on our Website. We use this information to do internal research on our users’ demographics, interests, and behaviour to better understand, protect and serve our users. This information is compiled and analysed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on our Website or not), which URL you next go to (whether this URL is on our Website or not), your computer browser information, and your IP address.</p>
        
            <h4 class="sub_title mt-4 mb-4 text-uppercase">How we use your personal information</h4>
            <ul class="header4 CheckListUl">
                <li>The personal information we collect allows us to keep you posted on ZLL’s latest product announcements, offers, and upcoming events.</li>
                <li>We also use personal information to help us create, develop, operate, deliver, and improve our products, services, content and advertising, and for loss prevention and anti-fraud purposes.</li>
                <li>We may use your personal information, including date of birth, to verify identity, assist with identification of users, and to determine appropriate services.</li>
                <li>From time to time, we may use your personal information to send important notices, such as communications about purchases and changes to our terms, conditions, and policies. Please note as this information is important to your interaction with ZLL, you may not opt out of receiving these communications.</li>
                <li>We may also use personal information for internal purposes such as auditing, data analysis, and research to improve our &nbsp;products, services, and customer communications.</li>
            </ul>
        
            <h4 class="sub_title mt-4 mb-4">COLLECTION AND USE OF NON-PERSONAL INFORMATION</h4>
            <p>We also collect data in a form that does not, on its own, permit direct association with any specific individual. We may collect, use, transfer, and disclose non-personal information for any purpose. The following are some examples of non-personal information that we collect and how we may use it:</p>
            <ul class="header4 CheckListUl">
                <li>We may collect information such as occupation, language, zip code, area code, , referrer URL, location, and the time zone where ZLL product and/or service is used so that we can better understand customer behavior and improve our products, services, and advertising.</li>
                <li>We may collect and store details of how you use our services, including search queries. This information may be used to improve the relevancy of results provided by our services. Except in limited instances to ensure quality of our services over the Internet, such information will not be associated with your IP address.</li>
                <li>With your explicit consent, we may collect data about how you use your device and applications in order to help app developers improve their apps.</li>
            </ul>
            <p class="mt-4">If we do combine non-personal information with personal information the combined information will be treated as personal information for as long as it remains combined.</p>
        
            <h4 class="sub_title mt-4 mb-4">COOKIES AND OTHER TECHNOLOGIES</h4>
            <p>A “cookie” is a small piece of information stored by a web server on a web browser so it can be later read back from that browser. Cookies are useful for enabling the browser to remember information specific to a given user. We place both permanent and temporary cookies in your computer’s hard drive. The cookies do not contain any of your personally identifiable information.</p>
            <p>ZLL use data collection devices such as “cookies” on certain pages of the Website to understand and analyze trends, to administer the site, to learn about user behavior on the site, to improve our product and services, measure promotional effectiveness, and promote trust and safety and to gather demographic information about our user base as a whole. ZLL may use this information in our marketing and advertising services.</p>
            <p>In some of our email messages, we use a “click-through URL” linked to content on the Website. When customers click one of these URLs, they pass through a separate web server before arriving at the destination page on our website. We track this click-through data to help us determine interest in particular topics and measure the effectiveness of our customer communications.</p>
            <p>“Cookies” are small files placed on your hard drive that assist us in providing our services. We offer certain features that are only available through the use of a “cookie”.</p>
            <p>We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are “session cookies,” meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the Website and you may be required to re-enter your password more frequently during a session.</p>
        
            <h4 class="sub_title mt-4 mb-4">DISCLOSURE TO THIRD PARTIES</h4>
            <p>At times ZLL may make certain personal information available to strategic partners that work with ZLL to provide products and services, or that help ZLL market to customers. For example, when you purchase any product and/or services from ZLL, you authorize ZLL to exchange the information you provide during the activation process to carry out service. Personal information will only be shared by ZLL to provide or improve our products, services and advertising; it will not be shared with third parties for any marketing purposes.</p>
        
            <h4 class="sub_title mt-4 mb-4">SERVICE PROVIDERS</h4>
            <p>ZLL shares personal information with companies who provide services such as information processing, fulfilling customer orders, delivering products to you, managing and enhancing customer data, assessing your interest in our products and services, and conducting customer research or satisfaction surveys. These companies are obligated to protect your information.</p>
        
            <h4 class="sub_title mt-4 mb-4">OTHERS</h4>
            <p>It may be necessary − by law, legal process, litigation, and/or requests from public and governmental authorities within or outside your country of residence − for ZLL to disclose your personal information. We may also disclose information about you if we determine that for purposes of national security, law enforcement, or other issues of public importance, disclosure is necessary or appropriate.</p>
            <p>We may also disclose information about you if we determine that disclosure is reasonably necessary to enforce our terms and conditions or protect our operations or users. Additionally, in the event of a reorganization, merger, or sale we may transfer any and all personal information we collect to the relevant third party.</p>
        
            <h4 class="sub_title mt-4 mb-4">INTEGRITY AND RETENTION OF PERSONAL INFORMATION</h4>
            <p>ZLL makes it easy for you to keep your personal information accurate, complete, and up to date. We will retain your personal information for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.</p>
        
            <h4 class="sub_title mt-4 mb-4">ACCESS TO PERSONAL INFORMATION</h4>
            <p>You can help ensure that your contact information and preferences are accurate, complete, and up to date by logging in to your account. For other personal information we hold, we will provide you with access for any purpose including to request that we correct the data if it is inaccurate or delete the data if ZLL is not required to retain it by law or for legitimate business purposes. We may decline to process requests that are frivolous/vexatious, jeopardize the privacy of others, are extremely impractical, or for which access is not otherwise required by local law.</p>
        
            <h4 class="sub_title mt-4 mb-4">LOCATION-BASED SERVICES</h4>
            <p>To provide location-based services on ZLL products, ZLL and licensees may collect, use, and share precise location data, including the real-time geographic location of your computer or device. Where available, location-based services may use GPS, Bluetooth, and your IP Address, along with crowd-sourced Wi-Fi hotspot and, and other technologies to determine your devices’ approximate location. Unless you provide consent, this location data is collected anonymously in a form that does not personally identify you and is used by ZLL and our licensees to provide and improve location-based products and services. For example, your device may share its geographic location with application providers when you opt in to their location services.</p>
        
            <h4 class="sub_title mt-4 mb-4">THIRD-PARTY SITES AND SERVICES</h4>
            <p>ZLL websites, products, and services may contain links to third-party websites, products, and services. Our products and services may also use or offer products or services from third parties, which may include such things as location data or contact details, is governed by their privacy practices. We encourage you to learn about the privacy practices of those third parties.</p>
        
            <h4 class="sub_title mt-4 mb-4">ZLL’S COMMITMENT TO YOUR PRIVACY</h4>
            <p>To make sure your personal information is secure, we communicate our privacy and security guidelines to all ZLL employees and strictly enforce privacy safeguards within the company.</p>
        
            <h4 class="sub_title mt-4 mb-4">PRIVACY QUESTIONS</h4>
            <p>If you have any further questions or concerns about ZLL’s Privacy Policy or data processing or if you would like to make a complaint about a possible breach of local privacy laws, please&nbsp;<a href="/locateUs" target="_blank">Locate Us</a>. You can always contact us by contact details provided in our website</p>
            <p>All such communications are examined and replies issued where appropriate as soon as possible. If you are unsatisfied with the reply received, you may refer your complaint to the relevant regulator in your jurisdiction. If you ask us, we will endeavor to provide you with information about relevant complaint avenues which may be applicable to your circumstances.</p>
            <p>ZLL may update its Privacy Policy from time to time. When we change the policy in a material way, a notice will be posted on our website along with the updated Privacy Policy.</p>
        </div>
      </div>
     
    </div>
   
 
</section>