<section>
    <div class="mainDiv">
        <div class="banner_content_slider_desk">
            <div id="carouselExampleControls" class="carousel slide">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../../../assets/img/new-kidzee/banner/pantamine_banner.png" class="d-block img-fluid slider_banner" alt="...">
                  </div>
                  <!-- <div class="carousel-item">
                    <img src="../../../assets/img/new-kidzee/banner/pantamine_banner.png" class="d-block img-fluid slider_banner" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../../../assets/img/new-kidzee/banner/pantamine_banner.png" class="d-block img-fluid slider_banner" alt="...">
                  </div> -->
                </div>
                <!-- <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button> -->
              </div>
              
        </div>
        <div class="banner_content_slider_mob">
        <img src="../../../assets/img/new-kidzee/banner/admission_res_banner.png" width="100%" alt="">
        </div>
        <h1 class="whiteTitle">Kidzee Preschool<br> Admissions Open for 2025-26.<br>Enquire Now</h1>
        <div class="subMenu_content">
            <app-sub-menu></app-sub-menu>
        </div>
      
        <div class="admissionForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="desk_res">
                    <div class="count_box">
                        <div class="count"> 
                            <div class="num_box">1</div>
                            <div class="count_para">
                                Fill the School Admission Inquiry 
                                Form on the Website
                            </div>  
                        </div>
                        <div class="count">   
                            <div class="num_box">2</div>
                            <div class="count_para">
                                We will invite you to the nearest Kidzee preschool
                            </div> 
                        </div>
                        <div class="count"> 
                            <div class="num_box">3</div>
                            <div class="count_para">
                                Visit the School for Campus Tour
                            </div>   
                        </div>
                        <div class="count">  
                            <div class="num_box">4</div>
                            <div class="count_para">
                                Join the Kidzee Family
                            </div>  
                        </div>
                    </div>
                </div>
                <div class="mob_res">
                    <div class="count_box_mob ">
                        <div id="carouselExampleIndicators" class="carousel slide">
                            <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
                                    aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                            </div>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div class="count"> 
                                        <div class="num_box">1</div>
                                        <div class="count_para">
                                            Fill the School Admission Inquiry 
                                            Form on the Website
                                        </div>  
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="count">   
                                        <div class="num_box">2</div>
                                        <div class="count_para">
                                            We will invite you to the nearest Kidzee preschool
                                        </div> 
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="count"> 
                                        <div class="num_box">3</div>
                                        <div class="count_para">
                                            Visit the School for Campus Tour
                                        </div>   
                                    </div>
                                </div>
                                <div class="carousel-item">
                                <div class="count">  
                                    <div class="num_box">4</div>
                                    <div class="count_para">
                                        Join the Kidzee Family
                                    </div>  
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="numb_list_mob">
                        <div id="carouselExampleIndicators1" class="carousel slide">
                            <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="0" class="active"
                                    aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="3"
                                    aria-label="Slide 4"></button>
                            </div>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div class="box">
                                        <div class="count_num">
                                            <div class="num_count">100%</div>
                                            <div class="label">SUPPORT</div>
                                        </div>
                                    <img src="../../../assets/img/new-kidzee/support.png" width="100%" alt="">
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="box">
                                        <div class="count_num">
                                            <div class="num_count">2,000+</div>
                                            <div class="label">PRESCHOOLS</div>
                                        </div>
                                    <img src="../../../assets/img/new-kidzee/support.png" width="100%" alt="">
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="col-md-3">
                                        <div class="box">
                                            <div class="count_num">
                                                <div class="num_count">750+</div>
                                                <div class="label">CITIES</div>
                                            </div>
                                        <img src="../../../assets/img/new-kidzee/support.png" width="100%" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="box">
                                        <div class="count_num">
                                            <div class="num_count">750+</div>
                                            <div class="label">ELEMENTS IN <br>STARTER KIT</div>
                                        </div>
                                    <img src="../../../assets/img/new-kidzee/support.png" width="100%" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">  
                  <app-admissions-form></app-admissions-form>
                </div>
            </div>
        </div>
        <img src="../../../assets/img/new-kidzee/chocolate_img.png" class="chocolate_img_content" alt="">
      <img src="../../../assets/img/new-kidzee/bag_img.png" class="bag_img_content" alt="">
      <img src="../../../assets/img/new-kidzee/btrfly_img.png" class="btrfly_img_content" alt="">
      <img src="../../../assets/img/new-kidzee/mouse_img.png" class="mouse_img_content" alt="">
        <div class="count_content">
            <div class="row">
                <div class="col-md-3">
                    <div class="box">
                        <div class="count_num">
                            <div class="num_count">100%</div>
                            <div class="label">SUPPORT</div>
                        </div>
                    <img src="../../../assets/img/new-kidzee/support.png" width="80%" alt="">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box">
                        <div class="count_num">
                            <div class="num_count">2,000+</div>
                            <div class="label">PRESCHOOLS</div>
                        </div>
                    <img src="../../../assets/img/new-kidzee/support.png" width="80%" alt="">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box">
                        <div class="count_num">
                            <div class="num_count">750+</div>
                            <div class="label">CITIES</div>
                        </div>
                    <img src="../../../assets/img/new-kidzee/support.png" width="80%" alt="">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box">
                        <div class="count_num">
                            <div class="num_count">750+</div>
                            <div class="label">ELEMENTS IN <br>STARTER KIT</div>
                        </div>
                    <img src="../../../assets/img/new-kidzee/support.png" width="80%" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="aboutUs_content">
            <app-about-us></app-about-us>
        </div>
        <div class="kidzeeSchool_content mt-5">
            <app-kidzee-pree-school></app-kidzee-pree-school>
        </div>
        <div class="faq_content">
            <app-faqs></app-faqs>
        </div>
 <div class="blogs_content">
            <app-blogs></app-blogs>
        </div>
        <div class="testimonial_content">
            <app-testimonial></app-testimonial>
        </div>
    </div>
</section>
