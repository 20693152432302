<section>
    <div class="franchise">
        <div class="box mt-5">
            <div class="row">
                <div class="col-md-6 d-flex justify-content-center">
                    <img src="../../../assets/img/MLZS/liter_img.png" class="liter_img" alt="">
                </div>
                <div class="col-md-6">
                    <div class="liter_content">
                        <h1><b>Information about</b><br>
                        <b>Litera Nova</b></h1>
                        <button class="btn read_mode mt-4">Know More <i class="fa fa-long-arrow-right mx-2"></i></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="box mt-5">
            <div class="franchise">
                <div class="row">
                    <div class="col-md-6">
                        <div class="title_content">
                            <h2><b>Franchise</b></h2>
                            <img src="../../../assets/img/MLZS/prg_text_bg.png" class="text_bg" alt="">
                            <p>Explore the world of high-quality education by partnering with Mount Litera, one of India's most trusted names in CBSE school franchises. Our franchise model offers aspiring partners an established platform to create the best school franchise in India, bringing transformative, value-driven education to communities across the country.</p>
                       
                         <div class="row mt-3">
                            <div class="col-1"><img src="../../../assets/img/MLZS/right_icon.png" width="15px" height="15px" alt=""></div>
                            <div class="col-11 p-0">Gross Enrolment Ratio in elementary expected to reach 100% by 2016 hence a steady flow of students</div>

                            <div class="col-1"><img src="../../../assets/img/MLZS/right_icon.png" width="15px" height="15px" alt=""></div>
                            <div class="col-11 p-0">Govt push for private players to enter the market</div>

                            <div class="col-1"><img src="../../../assets/img/MLZS/right_icon.png" width="15px" height="15px" alt=""></div>
                            <div class="col-11 p-0">20000-25000 ‘Quality‘ schools required (NCERT)</div>

                            <div class="col-1"><img src="../../../assets/img/MLZS/right_icon.png" width="15px" height="15px" alt=""></div>
                            <div class="col-11 p-0">Parents preference of sending their children into private schools (40% children get into 20% private schools)</div>

                            <div class="col-1"><img src="../../../assets/img/MLZS/right_icon.png" width="15px" height="15px" alt=""></div>
                            <div class="col-11 p-0">Schools have a much higher stickiness in terms of the students staying with them as it’s a 10 yr commitment from the parents</div>
                         </div>
                        <button class="btn read_mode mt-4">Know More <i class="fa fa-long-arrow-right mx-2"></i></button>
                        
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="fr_img_content w-100 d-flex justify-content-center">
                   <img src="../../../assets/img/MLZS/fr_img.png" alt="">
                </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>