import { Component } from '@angular/core';

@Component({
  selector: 'app-programmes-list',
  templateUrl: './programmes-list.component.html',
  styleUrls: ['./programmes-list.component.css']
})
export class ProgrammesListComponent {

}
