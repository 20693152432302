<section>
    <div class="mainDiv">
        <div class="banner_content">
            <div class="whiteTitle">{{headerTitle}}</div>
            <div class="subMenu_content">
                <app-sub-menu></app-sub-menu>
            </div>
            <img src="../../../assets/img/new-kidzee/home_icon.png" class="home_icon_content" alt="">
        </div>
        <div class="banner_content_slider">
            <div id="carouselExampleControls" class="carousel slide">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../../../assets/img/new-kidzee/banner/pantamine_banner.png" class="d-block w-100 desk_pt" alt="...">
                    <img src="../../../assets/img/new-kidzee/banner/pt_responsive.png" class="d-block w-100 res_pt" alt="...">
                  </div>
                  <!-- <div class="carousel-item">
                    <img src="../../../assets/img/new-kidzee/banner/pantamine_banner.png" class="d-block w-100 desk_pt" alt="...">
                    <img src="../../../assets/img/new-kidzee/banner/pt_responsive.png" class="d-block w-100 res_pt" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../../../assets/img/new-kidzee/banner/pantamine_banner.png" class="d-block w-100 desk_pt" alt="...">
                    <img src="../../../assets/img/new-kidzee/banner/pt_responsive.png" class="d-block w-100 res_pt" alt="...">
                  </div> -->
                </div>
                <!-- <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button> -->
              </div>
        </div>
        <div class="pentamind_img_content">
            <img src="../../../assets/img/new-kidzee/pantemind.png" class="pentaminde_img" alt="">
        </div>
        <div class="subContent">
            <div class="row">
                <div class="col-md-12">
                    <div class="pentemindeContent">
                        <img src="../../../assets/img/new-kidzee/chocolate_img.png" width="3%" class="chocolate_img" alt="">
                        <p><b>Péntemind - The Learning Minds</b>, our pedagogy nurtures the ‘Learning Minds’ in
                            every child. It shapes the child’s overall development in a fun and <b>inspiring manner</b>.</p>
                        <img src="../../../assets/img/new-kidzee/bag_img.png" width="3%" class="bag_content" alt="">
                       
                        <p>Our pedagogy Péntemind - The Learning Minds, resonates the belief that <b>"the empires of the
                                future will be empires of the mind,"</b> as eloquently stated by Winston Churchill.
                            Inspired by this profound insight, Péntemind is dedicated to nurturing the 'Learning Minds'
                            in every child with a vision of creating life long learners, by fostering their holistic
                            development in an engaging and inspiring manner.</p>
                          
                        <p>Péntemind, aims to shape future global leaders with a mindset of <b>'We First' rather than
                                'Me First,'</b> instilling in them the<b>values of collaboration, empathy, and
                                constructive contribution to society.</b> Through guided discovery, we empower children
                            to become adaptive, critically diligent individuals who are poised to make a positive impact
                            on the world.</p>
                            <img src="../../../assets/img/new-kidzee/btrfly_img.png" width="4%" class="btr_fly_img" alt="">
                          
                        <p><b>Péntemind is a set of 5 minds which are:</b></p>
                        <div class="desk_scr">
                        <div class="row ">
                            <div class="col-1"><img src="../../../assets/img/new-kidzee/emp.png" width="80%"></div>
                            <div class="col-11">
                                <p><b>Empathetic Mind :</b><span class="color_text"> Emotional Balance propogates real learninig.</span><br>
                                    Balancing self-awareness with consideration for others, the empathetic mind promotes both
                                    inward reflection and outward compassion, nurturing a sense of empathy and
                                    interconnectedness.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-1">
                                <img src="../../../assets/img/new-kidzee/con-mind.png" width="80%" alt="">
                            </div>
                            <div class="col-11">
                                <p><b>Conscientious Mind :</b><span class="color_text"> Knowledge Acquisition.</span><br>
                                    Grounded in informed decision-making, the conscientious mind prioritises cognitive
                                    precision, equipping children with the discernment and responsibility to make thoughtful
                                    choices.</p>
                            </div>
                        </div>
                            
                       <div class="row">
                        <div class="col-1">
                            <img src="../../../assets/img/new-kidzee/foc-mind.png" width="80%" alt="">
                        </div>
                        <div class="col-11">
                            <p><b>Focused Mind :</b><span class="color_text"> Knowledge Retention.</span><br>
                                This is the mind that approaches tasks logically and persistently strives for mastery
                                through dedicated practice and effort, emphasising both willpower and skill development.</p>
                        </div>
                       </div>
                        
                            <img src="../../../assets/img/new-kidzee/fruit_img.png" width="2%" class="fruit_img" alt="">
                            <img src="../../../assets/img/new-kidzee/mouse_img.png" width="3%" class="mouse_img" alt="">
                            <div class="row">
                                <div class="col-1">
                                    <img src="../../../assets/img/new-kidzee/ana-mind.png" width="80%" alt="">
                                </div>
                                <div class="col-11">
                                    <p><b>Analytical Mind :</b><span class="color_text"> Knowledge Application.</span><br>
                                        The analytical mind is adept at making decisions based on logic, employing a process of
                                        observation, analysis, synthesis, and evaluation to tackle challenges and solve problems
                                        effectively.</p>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-1">
                                    <img src="../../../assets/img/new-kidzee/inv-mind.png" width="80%" alt="">
                                </div>
                                <div class="col-11">
                                    <p><b>Inventive Mind :</b><span class="color_text"> Knowledge Development.</span><br>
                                        Encouraging creative thinking beyond conventional boundaries, the inventive mind fosters
                                        imagination and innovation, inspiring children to explore new ideas and possibilities.
                                        By cultivating these five minds in tandem, Péntemind aims to empower children with the
                                        essential skills, values, and perspectives necessary to thrive in an ever-evolving world.
                                        Join us on this transformative journey of learning and growth, where the potential of every
                                        child is nurtured and celebrated.</p>
                                </div>
                            </div>
                      
                        </div>
                        <div class="mob_scr mt-3">
                            <div id="carouselExampleIndicators" class="carousel slide">
                                <div class="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
                                        aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                                        aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                                        aria-label="Slide 3"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"
                                        aria-label="Slide 4"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4"
                                        aria-label="Slide 5"></button>
                                </div>
                                <!-- <p><b>Péntemind is a set of 5 minds which are:</b></p> -->
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <div class="img_list mt-2">
                                            
                                            <div class="d-flex justify-content-center"><img src="../../../assets/img/new-kidzee/emp.png" class="icon_list"></div>
                                           <div class="text-center mt-2"><b>Empathetic Mind:</b></div>
                                            <div class="text-center mt-2">
                                                <p><span class="color_text"> Emotional Balance propogates real learninig.</span><br>Balancing self-awareness with consideration for others, the empathetic mind promotes both inward reflection and outward compassion, nurturing a sense of empathy and interconnectedness.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <div class="img_list mt-5">
                                                <div class="d-flex justify-content-center"><img src="../../../assets/img/new-kidzee/con-mind.png" class="icon_list"></div>
                                               <div class="text-center mt-2"><b>Conscientious Mind:</b></div>
                                                <div class="text-center mt-2">
                                                        <p><span class="color_text"> Knowledge Acquisition.</span><br>
                                                            Grounded in informed decision-making, the conscientious mind prioritises cognitive precision, equipping children with the discernment and responsibility to make thoughtful choices.</p>
                                            </div>  
                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                            <div class="img_list mt-2">
                                                <div class="d-flex justify-content-center"><img src="../../../assets/img/new-kidzee/foc-mind.png" class="icon_list"></div>
                                               <div class="text-center mt-2"><b>Focused Mind:</b></div>
                                                <div class="text-center mt-2">
                                                    <p><span class="color_text"> Knowledge Retention.</span><br>
                                                        This is the mind that approaches tasks logically and persistently strives for mastery through dedicated practice and effort, emphasising both willpower and skill development.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <div class="img_list mt-2">
                                            <div class="d-flex justify-content-center"><img src="../../../assets/img/new-kidzee/ana-mind.png" class="icon_list"></div>
                                           <div class="text-center mt-2"><b>Analytical Mind :</b></div>
                                            <div class="text-center mt-2">
                                                <p><span class="color_text">Knowledge Application.</span><br>
                                                    The analytical mind is adept at making decisions based on logic, employing a process of observation, analysis, synthesis, and evaluation to tackle challenges and solve problems effectively.</p>
                                        </div>
                                    </div>
                                </div>
                                    <div class="carousel-item">
                                            <div class="img_list mt-2">
                                                <div class="d-flex justify-content-center"><img src="../../../assets/img/new-kidzee/ana-mind.png" class="icon_list"></div>
                                               <div class="text-center mt-2"><b>Inventive Mind:</b></div>
                                                <div class="text-center mt-2">
                                                    <p><span class="color_text"> Knowledge Development.</span><br>
                                                        Encouraging creative thinking beyond conventional boundaries, the inventive mind fosters imagination and innovation, inspiring children to explore new ideas and possibilities. By cultivating these five minds in tandem, Péntemind aims to empower children with the essential skills, values, and perspectives necessary to thrive in an ever-evolving world. Join us on this transformative journey of learning and growth, where the potential of every child is nurtured and celebrated.</p>
                                                       
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

   <div class="pr_img_content my-3">
    <img src="../../../assets/img/new-kidzee/pantamind_new_img.svg" width="100%" alt="">
   </div>

                        <p>To foster the development of the 5 minds, we have taken the approach of Metacognition
                            which emphasise on Learn-Unlearn-Relearn.</p>
                         <p>Péntemind's Unique Offerings as an Integrated Curriculum</p>
                         <div class="row">
                            <div class="col-1"></div>
                            <div class="col-11">
                                <p><b>Artsy</b> - A path breaking art program that will teach children not only art but also
                                    appreciation of art </p>
                            </div>
                         </div>
                        
                         <div class="row">
                            <div class="col-1"></div>
                            <div class="col-11">
                                <p><b>Mental Might</b> - A program that will help children understand diversity, inclusion, and
                                    make them aware of the interconnectedness </p>
                              
                            </div>
                         </div>
                         <div class="row">
                            <div class="col-1"></div>
                            <div class="col-11">
                                <p><b>Showstopper</b> - A program that will teach children drama from planning to execution </p>
                       
                            </div>
                         </div>
                         <div class="row">
                            <div class="col-1"></div>
                            <div class="col-11">
                                <p><b>Tell-a-Tale</b> - A program that provides opportunity to listen to stories that challenge
                                    gender stereotypes, understand right from wrong, scribble their own magical world</p> 
                            </div>
                         </div>
                         <div class="row">
                            <div class="col-1"></div>
                            <div class="col-11">
                                <p><b>Whirl & Twirl</b> - A program that focus on the 5 elements (Body, Action, Time, Space, and
                                    Energy) of dance</p> 
                            </div>
                         </div>
                         <div class="row">
                            <div class="col-1"></div>
                            <div class="col-11">
                                <p><b>Personality Development</b> - A program that will help children enhance children’s
                                    self-esteem and self-confidence</p> 
                            </div>
                         </div>
                       
                            <img src="../../../assets/img/new-kidzee/fish_img.png" width="3%" class="fish_img" alt="">
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-11">
                                    <p><b>Critical Thinking</b> - A program that will help children develop systematic ways of
                                        thinking and reasoning</p>
                                </div>
                             </div>
                             <div class="row">
                                <div class="col-1"></div>
                                <div class="col-11">
                                    <p><b>Eco-Conscious</b> - A program that teaches children to lead sustainable lives and make
                                        eco-friendly choices</p>
                                </div>
                             </div>
                      
                            <img src="../../../assets/img/new-kidzee/chocolate_img.png" width="3%" class="chocolate_img" alt="">
                        
                    </div>
                </div>
            </div>
             
        </div>
        <!-- <div class="learningMindImgList">
        <div class="learningTitle text-center">Meet our Learning minds</div>
        <div class="row mt-5">
            <div class="col-md-1"></div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey11.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Goes deep into a topic with focus and
                            single-minded attention.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey22.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Selects crucial information from the copious amounts available and arrays that information in ways that make sense to self and others.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey33.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Goes beyond existing knowledge to pose new questions and offer new solutions.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey44.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Responds sympathetically and constructively to differences among individuals.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey55.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Lives in harmony with the natural world.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
       
      </div> -->
    </div>


</section>