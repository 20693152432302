<form [formGroup]="admissionForm">
    <div class="form_content">
        <div class="h1">Enquire Now</div>
        <div class="form-group mt-2">
            <input type="text" class="form-control" placeholder="First Name*" formControlName="fname"
            [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" />
            <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                <span class="text-danger msg_txt" *ngIf="(f.fname.touched || submitted) && f.fname.errors?.required">
                   First Name is required
                </span>
            </div>
        </div>
        <div class="form-group mt-2">
            <input type="text" class="form-control" placeholder="Last Name*" formControlName="lname"
            [ngClass]="{ 'is-invalid': submitted && f.lname.errors }" />
            <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                <span class="text-danger msg_txt" *ngIf="(f.lname.touched || submitted) && f.lname.errors?.required">
                   Last name is required
                </span>
            </div>
        </div>
        <div class="form-group mt-2">
            <input type="text" class="form-control" placeholder="Email*" formControlName="email"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <span class="text-danger msg_txt" *ngIf="(f.email.touched || submitted) && f.email.errors?.required">
                    Email is required
                </span>
                <span class="text-danger msg_txt" *ngIf="f.email.touched && f.email.errors?.email">
                    Enter a valid email address
                </span>
            </div>
        </div>
    
    <div class="row">
        <div class="col-md-6">
            <div class="form-group mt-2">
                <input type="text" class="form-control" placeholder="Mobile*"  (keyup)="getMobileNO();" formControlName="mobile"
                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" >
                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                    <span *ngIf="f.mobile.errors?.required">Mobile is required.</span>
                    <span *ngIf="f.mobile.errors?.pattern">Please, Enter 10 digit Mobile.</span>
                  
                </div>
                <div *ngIf="otpInput" class="validMsg">OTP sent successfully</div>
            </div>
            <div class="form-group mt-1" *ngIf="otpInput">
                <input type="text" class="form-control" placeholder="Enter OTP*"  (keyup)="verifyOTP();" formControlName="otp"
                [ngClass]="{ 'is-invalid': submitted && f.otp.errors }" >
                <div *ngIf="submitted && f.otp.errors" class="invalid-feedback">
                    <span *ngIf="f.otp.errors?.required">OTP is required.</span>
                </div>
                <div *ngIf="otp_ValidMsg" class="validMsg">OTP verified successfully</div>
                <div *ngIf="otp_inValidMsg" class="inValidMsg">please enter valid otp</div>
            </div>
            </div>
            <div class="col-md-6">
        <div class="form-group mt-2">
            <input type="text" class="form-control" placeholder="Pin Code*" [(ngModel)]="selectedpincode" formControlName="pinCode"
            [ngClass]="{ 'is-invalid': submitted && f.pinCode.errors }">
            <div *ngIf="submitted && f.pinCode.errors" class="invalid-feedback">
                <span *ngIf="f.pinCode.errors?.required">Pin code is required.</span>
                <span *ngIf="f.pinCode.errors?.pattern">Please, Enter 6 digit pincode.</span>
            </div>
        </div>
            </div>
      
    </div>
       
        
        <div class="row">
            <div class="col-md-6">
                <div class="form-group mt-2">
                    <select name="" id="" class="form-control" #ddlcountry [(ngModel)]="selectedcountry" formControlName="country" (change)="selectCountry(ddlcountry.value)"
                    [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                        <option value="">Country*</option>
                        <option value="{{country.Country_Name}}" *ngFor="let country of countryList">{{country.Country_Name}}</option>
                    </select>
                    <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                        <span class="text-danger msg_txt"
                            *ngIf="(f.country.touched || submitted) && f.country.errors?.required">
                            Country is required
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
        <div class="form-group mt-2">
            <select name="" id="" class="form-control" [(ngModel)]="selectedstate" formControlName="state" (change)="selectState($event)"
            [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
                <option value="">State/District*</option>
                <option value="{{state.State_Name}}" *ngFor="let state of stateList">{{state.State_Name}}</option>
            </select>
            <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                <span class="text-danger msg_txt" *ngIf="(f.state.touched || submitted) && f.state.errors?.required">
                    State/District is required
                </span>
            </div>
        </div>
            </div>
        </div>
       <div class="row">
        <div class="col-md-6">
            <div class="form-group mt-2">
                <select name="" id="" class="form-control" [(ngModel)]="selectedCity" formControlName="city" (change)="selectCity($event)"
                [ngClass]="{ 'is-invalid': submitted && f.city.errors }" >
                    <option value="">City*</option>
                    <option value="{{city.City_Name}}" *ngFor="let city of cityList">{{city.City_Name}}</option>
                </select>
                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                    <span class="text-danger msg_txt" *ngIf="(f.city.touched || submitted) && f.city.errors?.required">
                        City is required
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group mt-2">
                <select name="" id="" class="form-control" [(ngModel)]="selectedDevice" formControlName="location" (change)="selectCentet(selectedDevice)" 
                [ngClass]="{ 'is-invalid': submitted && f.location.errors }" >
                    <option value="">Location*</option>
                    <option value="{{franchise.Franchisee_Code}}" *ngFor="let franchise of franchiseeList">{{franchise.Franchisee_Name}}</option>
                </select>
                <div *ngIf="submitted && f.location.errors" class="invalid-feedback">
                    <span class="text-danger msg_txt" *ngIf="(f.location.touched || submitted) && f.location.errors?.required">
                        Location is required
                    </span>
                </div>
            </div>
        </div>
       </div>
       
      
        <div class="form-group mt-2 captcha_content">
            <label for="" class="lebel_text">Enter the text as displayed</label>
            <div class="box">
                <div class="box-CAPTCHA" id="box-CAPTCHA">
                    <div class="CAPTCHA" id="CAPTCHA">{{gen_captcha}}</div>
                    <!-- <div *ngFor="let captcha of captchaText">
                        <b>{{captcha}}</b>
                    </div> -->
                    <div class="captcha_content_flex">
                    <input type="text" id="input" placeholder="Type CAPTCHA Here" maxlength="6">
                
                    <div id="wrong" style="margin-left: 10px;color: #f00;"></div>
                    <div id="done" style="margin-left: 10px;color: #080;"></div>
                </div>
                </div>
            
             </div>
           
        </div>
        <div class="form-group mt-0 d-flex justify-content-center">
            <!-- <div class="submit_btn text-center" id="submit_form" (click)="validationForm()" >Submit</div> -->
            <div class="submit_btn text-center"  id="submit" (click)="validationForm()" >Submit</div>
        </div>
    </div>
</form>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
