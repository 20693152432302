<section>
    <div class="mainDiv">
        <div class="banner_content">
        <img src="../../../assets/img/new-kidzee/dr_blue_bg.png" class="yello_bg_img" height="100%" alt="">
        <img src="../../../assets/img/new-kidzee/program_header_img.png" class="yello_bg_img_mobile" height="100%" alt="">
        <img src="../../../assets/img/new-kidzee/abcd.png" class="three_child" alt="">
        <img src="../../../assets/img/new-kidzee/left_img.png" class="left_img" alt="">
            <h1 class="whiteTitle">{{headerTitle}}</h1>
            <div class="subMenu_content">
                <app-sub-menu></app-sub-menu>
            </div>
            <img src="../.././../assets/img/new-kidzee/btrfly_img.png" class="btr_fly_img" alt="">
        </div>

        <div class="sub_content">
            <div class="row">
                <div class="col-md-12">
                    <div class="text_content">
                        <img src="../../../assets/img/new-kidzee/chocolate_img.png" class="chocolate_img" alt="">
                        <div class="paper_content">
                            <div class="infoContent mt-3">
                                <div class="text1">
                                    <div class="sub_text_black">Age Group</div>
                                    <div class="sub_text">2.6 - 3.5 Years</div>
                                </div>
                                <div class="text2">
                                    <div class="sub_text_black">Duration</div>
                                    <div class="sub_text">3 Hours/Day</div>
                                </div>
                            </div>
                        </div>
                        <img src="../../../assets/img/new-kidzee/bag_img.png" class="bag_img" alt="">
                
                        <div class="paraText mt-4">
                            <p>*As per RTE- Right to Education and NEP- National Education Policy from state to state.
                            </p>
                            <p>The child is now identified as a <b>Pre-schooler</b>.
                                To set a firm foundation in the early years of preschool, quality education is vital for the overall development of a child. Our nursery curriculm has a right array of activities that helps a child move towards achieving their early learning goals.</p>
                        <p>Quality education in the early years of preschool lays a strong foundation for the overall development of a child. The nursery curriculum engages children in <b>FLN- (Foundation in literacy and Numeracy)</b> school readiness activities such as reading, writing, counting, number value, and problem-solving in purposeful manner through play. Children are encouraged to display their learning through collaborative activities in the areas of dramatics, science, and arts.</p>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contentList">
            <div class="row">
                <div class="col-md-4">
                    <div class="listTitle">Key Areas in Playgroup Curriculum</div>
                    <div class="text_list">
                        <ul>

                          <li><span class="liText">'Panchkosha' integrated</span></li>
                          <li><span class="liText">Developing oral communication skills</span></li>
                          <li><span class="liText">Learning to express through art and drama</span></li>
                          <li><span class="liText">Language Development (Phonics )</span></li>
                          <li><span class="liText">Math Development (Number Value)</span></li>
                          <li><span class="liText">Opportunities for advanced fine and gross motor development</span></li>
                          <li><span class="liText">Facilitating cognitive development (Sorts using two attributes)</span></li>
                         </ul>
                    </div>
                </div>
                <div class="col-md-4 kidzee_session">
                    <div class="listTitle">Kidzee Sessions</div>
                    <div class="text_list">
                        <ul>
                            <li><span class="liText">Circle Time</span></li>
                            <li><span class="liText">Free Play</span></li>
                            <li><span class="liText">Knowledge Time</span></li>
                            <li><span class="liText">Language Time</span></li>
                            <li><span class="liText">English Readiness Programme</span></li>
                            <li><span class="liText">Artsy</span></li>
                            <li><span class="liText">Personality Development</span></li>
                            <li><span class="liText">Showstopper</span></li>
                            <li><span class="liText">Mental Might</span></li>
                            <li><span class="liText">Eco-conscious</span></li>
                            <li><span class="liText">Numeracy Time</span></li>
                            <li><span class="liText">Tell-a-Tale</span></li>
                            <li><span class="liText">Library</span></li>
                            <li><span class="liText">Talk Time</span></li>
                            <li><span class="liText">Sciencify</span></li>
                            <li><span class="liText">Outdoor</span></li>
                            <li><span class="liText">Indoor</span></li>
                            <li><span class="liText">Whirl & Twirl</span></li>
                            <li><span class="liText">Sensorium</span></li>
                            <li><span class="liText">Critical Thinking</span></li>
                            <li><span class="liText">Celebration of festivals and special days</span></li>
                            <li><span class="liText">Field Trips</span></li>
                            <li><span class="liText">Puppet shows and skits</span></li>
                        </ul>
                    </div>
                  
                </div>
                <div class="col-md-4">
                    <div class="listTitle">Kidzee Special</div>
                    <div class="text_list">
                        <ul>
                            <li><span class="liText">English Readiness Programme</span></li>
                            <li><span class="liText">Introduciton to Phonics.</span></li>
                            <li><span class="liText">Artsy</span></li>
                            <li><span class="liText">Personality Development</span></li>
                            <li><span class="liText">Showstopper</span></li>
                            <li><span class="liText">Mental Might</span></li>
                            <li><span class="liText">Eco-conscious</span></li>
                            <li><span class="liText">Tell-a-Tale</span></li>
                            <li><span class="liText">Library</span></li>
                            <li><span class="liText">Sciencify</span></li>
                            <li><span class="liText">Whirl & Twirl</span></li>
                            <li><span class="liText">Sensorium</span></li>
                            <li><span class="liText">Critical Thinking - Problem solving</span></li>
                            <li><span class="liText">Intergrated Sanskar</span></li>
                            <li><span class="liText">Augmented Reality - Fantasy Box</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="faqs_content">
            <app-faqs></app-faqs>
        </div>
        <div class="blogs_content">
            <app-blogs></app-blogs>
        </div>
       
    </div>
    <!-- <div class="bottomMonkey">
        <img srcset="../../../assets/img/monkey1.png" loading="lazy" class="monkeyImgList" alt="">
    </div> -->
</section>