<div class="aboutUs">
    <div class="sub_content">
    <div class="row flex-column-reverse flex-lg-row">
        <div class="col-md-6">
            <img src="../../../assets/img/new-kidzee/scale.png" class="scale_img" alt="">
            <img src="../../../assets/img/new-kidzee/bag_img.png" class="bag_img_content" alt="">
            <div class="monkeyContent">
                <img srcset="../../../assets/img/monkey1.png" loading="lazy" alt="" class="monkey1">
                <img src="../../../assets/img/new-kidzee/mouse_img.png" class="mouse_img_content" alt="">
            </div>
            <img src="../../../assets/img/new-kidzee/pen.png" class="pen_img_content" alt="">
        </div>
        <div class="col-md-6">
            <div class="text_content">
                <div class="right_content1">
                    <h1 class="title">
                        About Us<br>
                        <img src="../../../assets/img/new-kidzee/bottom_img.png" class="bottom_img_content" alt="">
                    </h1>
                    <div class="subTitle mt-2">
                        Kidzee Preschool
                    </div>
                  
                    <div class="paragraph_content mt-2">
                        <p>Kidzee Preschool is a leading chain of preschools located in India and Nepal backed by Zee Learn Ltd. With more than two decades of experience in the preschool industry, Kidzee has nurtured more than 1.5 million children throughout India. Being one of the largest preschools in India. Kidzee has 2000+ centres in 750+ cities in India and Nepal.</p>
                        <div class="subTitle mt-2">Kidzee Advantage</div>
                            <p class="mt-2">Kidzee is a <b>leader</b> and a trendsetter in <b>Early Childhood Care and Education (ECCE).</b> Our business partners’ passion and drive are vital for our brand’s success.</p>
                            <p>Our Business Partners don't just take the name, they also take our <b>pedagogy- PéNTEMiND,</b> guidelines to teach, prescribes books/plays to be followed and so on to the ground.</p>
                            <p>Starting a preschool requires a strategic researching, planning, designing, and organising. <b>Kidzee Professional  research,</b> makes this journey easier and rewarding for all our Business Partners.</p>
                            <p><b>Secret to Success is Service and  Support.</b>We provide pre-opening and operational support apart from the regular support that is given additional to all systems and process.</p>
                            <img src="../../../assets/img/new-kidzee/fruit_img.png" class="fruit_img_content" alt="">
                        </div>
                    
                   
                </div>
            </div>
        </div>
    </div>
</div>
</div>