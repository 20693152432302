<app-static-header></app-static-header>
<section>
    <div class=content>
        <div class="div">
      <div class="div-2">
        <div class="div-3">Error</div>
        <div class="div-4">
          <div class="div-5">
            <div class="div-6">
              <!-- <div class="column">
                <img alt="kidzee"
                  loading="lazy"
                  src="../../../assets/img/found_kipee.svg"
                  class="img"
                />
              </div> -->
              <div class="column-2">
                <img alt="Kidzee"
                  loading="lazy"
                  src="../../../assets/img/pageNotFOund.svg"
                  class="img-2"
                />
              </div>
            </div>
          </div>
          <div class="div-7 mt-5 text-center">
            <div>Seems our Kapih can’t find the page your’re looking for.</div>
            <button class="submit_btn" routerLink="">Go To HomePage</button>
          </div>
         
          <!-- <img alt="kidzee"
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7128c957ae645520a8b59cac373287afdef8b53969f55c7e647ebbc78c64ad69?"
            class="img-3"
          /> -->
        </div>
      </div>
    </div>
      </div>
        </section>
  <app-footer></app-footer>
