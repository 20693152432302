<section>
    <div class="blogs py-4">
        <div class="box mt-5">
            <div class="row">
                <div class="col-md-6">
                    <div class="blog_text_content">
                        <div class="title_content">
                        <h2><b>Blogs</b></h2>
                        <img src="../../../assets/img/MLZS/prg_text_bg.png" class="text_bg" alt="">
                        <p>
                            Read up engaging stories and articles, helpful tips, and updates, offering a unique glimpse into the exciting events and experiences that define life at Mount Litera Zee School.
                        </p>
                    </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="blog_main_img">
                        <img src="../../../assets/img/MLZS/blog_main_img.png" alt="blog_main_img">
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-md-4">
                    <div class="blog_sub_img d-flex justify-content-center">
                        <img src="../../../assets/img/MLZS/blog1.png" alt="">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="blog_sub_img d-flex justify-content-center">
                        <img src="../../../assets/img/MLZS/blog2.png" alt="">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="blog_sub_img d-flex justify-content-center">
                        <img src="../../../assets/img/MLZS/blog3.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>