<div class="preSchoolbgImg_content">
    <div class="sub_content">
        <img src="../../../assets/img/new-kidzee/blue_home.png" class="home_img_content" alt="">
        <h1 class="title text-center">
            <span class="day_care_color"> Why Choose </span>
            <br>Kidzee Preschool?
        </h1>
        <div class="content">
            <p class="text-center">Kidzee Preschool is your experienced trusted partner in providing exceptional early
                education for your child. Here's why Kidzee stands out as the preferred choice for parents seeking the
                best for their little ones</p>
        </div>
        <div id="carouselExampleIndicators" class="carousel slide">
            <div class="carousel-indicators">
                <!-- <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                    aria-label="Slide 3"></button> -->
            </div>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="img_list mt-5">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="main_box">
                                    <div class="img_box">
                                        <img src="../../../assets/img/new-kidzee/holistic.png" width="100%" alt="">
                                    </div>
                                    <div class="info_content">
                                        <div class="img_sub_title">
                                            Holistic Learning
                                        </div>
                                        <div>
                                            <p class="text-center">Fostering all-round development through a balanced blend of academics, play, and life skills.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="main_box">
                                    <div class="img_box">
                                        <img src="../../../assets/img/new-kidzee/circle_child.png" width="100%" alt="">
                                    </div>
                                    <div class="info_content">
                                        <div class="img_sub_title">
                                            Expert Educators
                                        </div>
                                        <div>
                                            <p class="text-center">Skilled teachers dedicated to nurturing every child’s unique potential.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="main_box">
                                    <div class="img_box">
                                        <img src="../../../assets/img/new-kidzee/safe.png" width="100%" alt="">
                                    </div>
                                    <div class="info_content">
                                        <div class="img_sub_title">
                                            Safe and Secure
                                        </div>
                                        <div>
                                            <p class="text-center">A protected, child-friendly environment ensuring safety and peace of mind.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="main_box">
                                    <div class="img_box">
                                        <img src="../../../assets/img/new-kidzee/boy.png" width="100%" alt="">
                                    </div>
                                    <div class="info_content">
                                        <div class="img_sub_title">
                                            Innovative Learning
                                        </div>
                                        <div>
                                            <p class="text-center">Engaging, modern teaching methods that make learning fun and impactful.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
        <img src="../../../assets/img/new-kidzee/mouse_img.png" class="mouse_img_content" alt="">
    </div>
    </div>
    
    <div class="mobileView">
        <img src="../../../assets/img/new-kidzee/blue_home.png" class="home_img_content" alt="">
        <h1 class="title text-center">
            <span class="day_care_color"> Why Choose </span>
            <br>Kidzee Preschool?
        </h1>
        <div class="content">
            <p class="text-center">Kidzee Preschool is your experienced trusted partner in providing exceptional early
                education for your child. Here's why Kidzee stands out as the preferred choice for parents seeking the
                best for their little ones</p>
        </div>
    <div id="carouselExampleDark1" class="carousel carousel-dark slide">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2"
                aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3"
                aria-label="Slide 4"></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="10000">
                <div class="main_box">
                    <div class="img_box">
                        <img src="../../../assets/img/new-kidzee/holistic.png" width="100%" alt="">
                    </div>
                    <div class="info_content">
                        <div class="img_sub_title">
                            Holistic Learning
                        </div>
                        <div>
                            <p class="text-center">Fostering all-round development through a balanced blend of academics, play, and life skills.</p>
               
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
                <div class="main_box">
                    <div class="img_box">
                        <img src="../../../assets/img/new-kidzee/circle_child.png" width="100%" alt="">
                    </div>
                    <div class="info_content">
                        <div class="img_sub_title">
                            Expert Educators
                        </div>
                        <div>
                            <p class="text-center">Skilled teachers dedicated to nurturing every child’s unique potential.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item" data-bs-interval="2000">
                <div class="main_box">
                    <div class="img_box">
                        <img src="../../../assets/img/new-kidzee/safe.png" width="100%" alt="">
                    </div>
                    <div class="info_content">
                        <div class="img_sub_title">
                            Safe and Secure
                        </div>
                        <div>
                            <p class="text-center">A protected, child-friendly environment ensuring safety and peace of mind.</p>
                        </div>
                    </div>
                </div>
            </div>
           

            <div class="carousel-item">
                <div class="main_box">
                    <div class="img_box">
                        <img src="../../../assets/img/new-kidzee/boy.png" width="100%" alt="">
                    </div>
                    <div class="info_content">
                        <div class="img_sub_title">
                            Innovative Learning
                        </div>
                        <div>
                            <p class="text-center">Engaging, modern teaching methods that make learning fun and impactful.</p>
                        </div>
                    </div>
                </div>

            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark1"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark1"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>


    </div>

</div>